<template>
  <div class="relative flex flex-col flex-1">
    <SelectionWidget
      v-if="selectable"
      v-show="selectionActive"
      :selection="selection"
      :actions="selectionActions"
      :items="items"
      :count="paginatable?.count || items.length"
      :noun="selectable.noun"
      @clear="selection = { mode: 'some', items: [] }"
      @select-all="selection = { mode: 'some', items: items }"
      @select-everything="selection = { mode: 'all' }"
    />

    <div class="flex flex-col flex-1 max-w-full max-h-screen overflow-y-scroll">
      <View.Container
        :breadcrumbs="props.breadcrumbs"
        :pending="
          props.pending && (!paginatable || paginatable.pages?.length === 1)
        "
        :items="items"
      >
        <template #actions>
          <Menu.Root>
            <Menu.Trigger>
              <button
                type="button"
                :class="{ 'lg:hidden': !props.forceDropdown }"
              >
                <EllipsisHorizontalIcon
                  :class="{
                    'size-4 lg:w-16': !props.forceDropdown,
                    'size-4': props.forceDropdown,
                  }"
                />
              </button>
            </Menu.Trigger>

            <Menu.Container>
              <Menu.RouteItem
                v-for="action in actions"
                :key="action.text"
                :icon="action.icon"
                :label="action.text"
                :to="action.route"
              />
              <Menu.Item
                v-if="exportable"
                icon="document-arrow-down"
                label="Export"
                :disabled="
                  exporter.state.status === 'pending' || items.length === 0
                "
                @click="exporter.confirm"
              />
            </Menu.Container>
          </Menu.Root>

          <slot name="actions" />

          <div
            v-if="!props.forceDropdown"
            class="hidden space-x-3 empty:hidden lg:flex"
          >
            <Action
              v-for="action in actions"
              :key="action.text"
              :text="action.text"
              :hotkey="action.hotkey"
              :variant="action.variant"
              :route="action.route"
            />
            <Action
              v-if="exportable"
              text="Export"
              hotkey="e"
              variant="secondary"
              :disabled="
                exporter.state.status === 'pending' || items.length === 0
              "
              @click="exporter.confirm"
            />
          </div>

          <Menu.Root v-if="customizable">
            <Menu.Trigger>
              <Menu.TriggerButton class="hidden lg:flex">
                Customize
              </Menu.TriggerButton>
            </Menu.Trigger>

            <Menu.Container>
              <template
                v-for="section in customizable.columnSpec.filter(
                  (section) => section.columns.filter((c) => !c.always).length
                )"
                :key="section.label"
              >
                <Menu.SectionHeader>{{ section.label }}</Menu.SectionHeader>
                <Menu.ToggleItem
                  v-for="metric in section.columns.filter((c) => !c.always)"
                  :key="metric.key"
                  :label="metric.label"
                  :value="metric.key"
                  :model-value="columns"
                  @update:model-value="$emit('update:columns', $event)"
                />
              </template>
            </Menu.Container>
          </Menu.Root>

          <ParameterWidget
            v-if="parameterizable"
            :parameter-set="parameterizable.parameterSet"
            :default-parameters="parameterizable.defaultParameters"
            :model-value="parameterizable.parameters"
            :aggregates="parameterizable.aggregates"
            :count="paginatable?.count || items.length"
            @update:model-value="$emit('update:parameters', $event)"
          />
        </template>
        <template #callouts>
          <slot name="callouts" />
        </template>
        <template v-if="error" #empty>
          <div class="text-center mx-auto flex justify-center flex-col">
            <EmptyState icon="no-symbol" header="Error">
              {{ error }}
            </EmptyState>
          </div>
        </template>
        <template
          v-else-if="
            $slots.empty &&
            items.length === 0 &&
            !pending &&
            emptiable !== undefined
          "
          #empty
        >
          <div
            v-if="emptiable"
            class="text-center mx-auto flex justify-center flex-col"
          >
            <EmptyState :icon="emptiable.icon" :header="emptiable.header">
              <slot name="empty" />
            </EmptyState>
          </div>
        </template>

        <template v-if="items.length !== 0" #body>
          <Table
            v-if="newTables"
            :items="items"
            :group="group"
            :columns="fullColumns"
            :hide-header="hideHeader"
            :orders="orderable?.orders"
            :order="orderable?.order"
            :selection="selectable ? selection : undefined"
            @model-clicked="handleModelClicked"
            @update:order="$emit('update:order', $event)"
          >
            <template #row="rowProps">
              <slot
                name="row"
                v-bind="rowProps"
                :parameters="parameterizable?.parameters"
              ></slot>
            </template>

            <template #footer>
              <div
                v-if="paginatable?.pages && hasNextPage"
                :class="[
                  `flex justify-center p-4`,
                  !selectionActive ? `pb-6` : `pb-0`,
                ]"
              >
                <ActionButton
                  :disabled="pending"
                  :pending="paginatable.pages.length > 0 && pending"
                  text="Load more"
                  @click="loadNextPage"
                />
              </div>

              <div v-if="selectionActive" class="pb-action-gutter"></div>
            </template>
          </Table>

          <DataTable
            v-else
            :order="orderable?.order"
            :items="items"
            :columns="fullColumns"
            :pending="pending && paginatable && paginatable.pages?.length > 1"
            :orders="orderable?.orders"
            variant="view"
            :handle-model-clicked="handleModelClicked"
            :parameters="parameterizable?.parameters"
            :selection="selection"
            :group="group"
            :hide-header="hideHeader"
            :draggable="draggable?.enabled"
            @update:order="$emit('update:order', $event)"
            @update:items="$emit('update:items', $event)"
          >
            <template v-if="paginatable?.pages && hasNextPage" #action>
              <div class="flex justify-center">
                <ActionButton
                  :disabled="pending"
                  :pending="paginatable.pages.length > 0 && pending"
                  text="Load more"
                  @click="loadNextPage"
                />
              </div>
            </template>
            <template v-if="selectionActive" #postscript>
              <div class="h-20" />
            </template>
            <template #row="rowProps">
              <slot name="row" v-bind="rowProps" />
            </template>
            <template v-if="$slots['row-actions']" #row-actions="{ item }">
              <slot name="row-actions" :item="item" />
            </template>
          </DataTable>
          <slot name="footer" />
        </template>
      </View.Container>
      <RouterView />

      <ExportDialog
        :exporter="exporter"
        :count="props.paginatable?.count || items.length"
        :parameters="parameterizable?.parameters"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="Model extends { id: string }, Column extends string, Order extends Column | `-${Column}`"
>
import { computed, Ref, ref, watch } from "vue";
import { RouterView } from "vue-router/auto";

import { components as OpenAPI } from "@/autogen/openapi";
import DataTable, {
  DataTableRowProps,
} from "@/components/DataTable/DataTable.vue";
import EmptyState from "@/components/Layout/ListView/EmptyState.vue";
import { ColumnSpec, Selection } from "@/components/Layout/ListView/lib";
import SelectionWidget from "@/components/Layout/ListView/SelectionWidget.vue";
import { SelectionAction } from "@/components/Layout/ListView/types";
import ParameterWidget from "@/components/Layout/ParameterWidget/Component.vue";
import {
  DefaultParameter,
  Parameter,
  ParameterSet,
} from "@/components/Layout/ParameterWidget/lib";
import ActionButton from "@/components/Utilities/ActionButton.vue";
import Menu from "@/design_system/Menu";
import View, { Props as ViewProps } from "@/design_system/View";
import Table from "@/design_system/View/Table.vue";
import EllipsisHorizontalIcon from "@/icons/heroicons/ellipsis-horizontal-micro.svg";
import type { MappedIconMicro } from "@/icons/icon-mapping-micro";
import type { MappedIconMini } from "@/icons/icon-mapping-mini";
import { ExportDialog, useExport } from "@/lib/exportable";
import { useKeybinds } from "@/lib/hotkeys";
import { RouteLocation } from "@/router/types";
import { Aggregate } from "@/types/aggregate";

import Action, { Variant } from "./Action.vue";

type Props = {
  items: Model[];
  columns: Column[];
  error?: string;
  group?: string;
  hideHeader?: boolean;
  forceDropdown?: boolean;
  newTables: boolean;
};

type OrderableProps = {
  order: Order;
  orders: readonly Order[];
};

type EmptiableProps = {
  header: string;
  icon: MappedIconMini;
};

type SelectableProps = {
  noun: string;
  registrar: (selection: Selection<Model>) => SelectionAction[];
};

type PaginatableProps = {
  pages: number[];
  pageSize: number;
  count: number;
};

type CustomizableProps = {
  columnSpec: ColumnSpec<Column>;
};

type DraggableProps = {
  enabled: true;
};

type ExportableProps = {
  collections: [OpenAPI["schemas"]["ExportCollection"]];
};

type ParameterizableProps = {
  parameters: Parameter<string>;
  defaultParameters: DefaultParameter<string>[];
  parameterSet: ParameterSet<string>[];
  aggregates: Record<string, Aggregate<string>[] | undefined>;
};

type ListProps = ViewProps &
  Props & {
    actionable?: {
      enabled: true;
    };
  } & { orderable?: OrderableProps } & { selectable?: SelectableProps } & {
    paginatable?: PaginatableProps;
  } & { customizable?: CustomizableProps } & {
    draggable?: DraggableProps;
  } & { parameterizable?: ParameterizableProps } & {
    emptiable?: EmptiableProps;
  } & { exportable?: ExportableProps } & {
    actions?: {
      text: string;
      route: RouteLocation;
      hotkey: string;
      variant: Variant;
      icon: MappedIconMicro;
    }[];
  };

const props = defineProps<ListProps>();

const hasNextPage = computed(() => {
  if (!props.paginatable) {
    return false;
  }
  return (
    props.paginatable.pages[props.paginatable.pages.length - 1] *
      props.paginatable.pageSize <
    props.paginatable.count
  );
});

const emit = defineEmits([
  "update:order",
  "update:items",
  "update:pages",
  "update:parameters",
  "update:columns",
]);

const loadNextPage = () => {
  if (props.paginatable) {
    const nextPage =
      props.paginatable.pages[props.paginatable.pages.length - 1] + 1;
    emit("update:pages", [...props.paginatable.pages, nextPage]);
  }
};

const selection = ref<Selection<Model>>({
  mode: "some",
  items: [],
}) as Ref<Selection<Model>>;
const selectionActive = computed(() => {
  return (
    props.selectable &&
    (selection.value.mode === "all" || selection.value.items.length > 0)
  );
});
const fullColumns = computed(() => {
  const relevantColumns = props.customizable
    ? props.customizable.columnSpec
        .flatMap((spec) => spec.columns.map((c) => c.key))
        .filter((c) => props.columns.includes(c))
    : props.columns;

  const shouldHaveLeftSpacer = props.selectable;
  return [
    ...(shouldHaveLeftSpacer ? ["LEFT_SPACER_SENTINEL"] : []),
    ...relevantColumns,
    ...(props.actionable?.enabled ? ["RIGHT_SPACER_SENTINEL"] : []),
  ];
});

const latestItemClicked = ref<Model | null>(null) as Ref<Model | null>;

const selectionActions = computed(() => {
  if (!props.selectable?.registrar) {
    return [];
  }

  const registrar = props.selectable.registrar;
  const actions = registrar(selection.value);

  return actions;
});

const removeSelectedModel = (model: Model) => {
  if (selection.value.mode === "all") {
    selection.value = {
      mode: "all_except",
      items: props.items.filter((s) => s.id === model.id),
    };
  } else {
    selection.value.items = selection.value.items.filter(
      (s: any) => s.id !== model.id
    );
  }
};

const toggleSelectedItem = (model: Model) => {
  if (selection.value.mode === "all") {
    selection.value = {
      mode: "all_except",
      items: props.items.filter((s) => s.id === model.id),
    };
  } else if (
    selection.value.mode === "all_except" &&
    selection.value.items.length === 1 &&
    selection.value.items[0].id === model.id
  ) {
    selection.value = {
      mode: "all",
    };
  } else {
    if (selection.value.items.find((s) => s.id === model.id)) {
      removeSelectedModel(model);
    } else {
      selection.value.items.push(model);
    }
  }
};

const handleModelClicked = (model: Model, e: any) => {
  // If the user is clicking on a button or a link, back out
  if (e.target.closest("button, a[href]")) {
    return;
  }

  // Okay this is some heavy logic.
  // If you're:
  // 1. Holding shift.
  // 2. Have already clicked something.
  // 3. Your last click was a toggle on, not a toggle off...
  // Then do mass select.
  if (
    e.shiftKey &&
    latestItemClicked.value &&
    (selection.value.mode === "all" ||
      selection.value.items
        .map((s) => s.id)
        .includes(latestItemClicked.value.id))
  ) {
    const lastClick = props.items.indexOf(latestItemClicked.value);
    const currentClick = props.items.indexOf(model);
    const sliceIndices =
      currentClick > lastClick
        ? [lastClick + 1, currentClick + 1]
        : [currentClick, lastClick];
    const relevantModels = props.items.slice(...sliceIndices);
    relevantModels.map(toggleSelectedItem);
  } else {
    toggleSelectedItem(model);
  }
  latestItemClicked.value = model;
};

const exporter = useExport(props.exportable?.collections || []);

watch(
  () => props.items,
  () => {
    if (selection.value.mode === "all") {
      selection.value = {
        mode: "all",
      };
    } else {
      selection.value = {
        mode: "some",
        items: selection.value.items.filter((s) =>
          props.items.map((i) => i.id).includes(s.id)
        ),
      };
    }
  }
);

useKeybinds(() => {
  if (!props.selectable) {
    return;
  }

  return {
    "$mod+a"(ev) {
      if (ev.defaultPrevented) {
        return;
      }

      selection.value = { mode: "some", items: props.items };
      return true;
    },
    Escape(ev) {
      if (ev.defaultPrevented) {
        return;
      }

      const current = selection.value;
      if (current.mode === "some" && current.items.length === 0) {
        return;
      }

      selection.value = { mode: "some", items: [] };
      return true;
    },
  };
});

defineSlots<{
  actions(props: {}): void;
  callouts(props: {}): void;
  empty?(props: {}): void;
  "empty-state"(props: {}): void;
  "row-actions"(props: { item: Model }): void;
  row(props: DataTableRowProps<Model>): void;
  footer(props: {}): void;
}>();
</script>
