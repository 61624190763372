<template>
  <ToastRoot
    :duration="getToastDuration(toast)"
    class="toast rounded-lg border border-gray-200 bg-white px-4 py-3 shadow-lg transition hover:border-gray-300 hover:shadow-xl"
    @update:open="removeToast(toast.id)"
  >
    <div role="status">
      <div class="flex items-start">
        <div class="mr-3 grid h-6 shrink-0 place-items-center empty:hidden">
          <ExclamationTriangleIcon
            v-if="toast.type === 'warn'"
            class="h-4 w-4 text-amber-600"
          />
          <ExclamationCircleIcon
            v-else-if="toast.type === 'error'"
            class="h-4 w-4 text-red-600"
          />
          <CheckCircleIcon
            v-else-if="toast.type === 'success'"
            class="h-4 w-4 text-green-600"
          />
          <ArrowPathIcon
            v-else-if="toast.type === 'pending'"
            class="h-4 w-4 animate-spin"
          />
        </div>

        <ToastTitle
          class="overflow-hidden break-words text-sm font-medium leading-6"
        >
          {{ toast.title }}
        </ToastTitle>

        <div class="grow"></div>

        <ToastClose
          aria-label="Close this toast"
          class="-mr-1 ml-4 grid h-6 w-6 shrink-0 place-items-center rounded text-gray-400 transition-colors hover:bg-gray-100 hover:text-gray-800"
        >
          <XMarkIcon class="h-4 w-4" />
        </ToastClose>
      </div>
      <ToastDescription
        v-if="toast.message"
        class="mt-1 break-words text-[13px] leading-5 text-gray-500"
      >
        {{ toast.message }}
      </ToastDescription>
    </div>
  </ToastRoot>
</template>

<script lang="ts" setup>
import { ToastClose, ToastDescription, ToastRoot, ToastTitle } from "radix-vue";

import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import CheckCircleIcon from "@/icons/heroicons/check-circle-micro.svg";
import ExclamationCircleIcon from "@/icons/heroicons/exclamation-circle-micro.svg";
import ExclamationTriangleIcon from "@/icons/heroicons/exclamation-triangle-micro.svg";
import XMarkIcon from "@/icons/heroicons/x-mark-micro.svg";
import { InternalToast, UNSTABLE_toasts as toasts } from "@/lib/toasts";

const getToastDuration = (toast: InternalToast): number | undefined => {
  if (window.location.pathname.includes("storybook")) {
    return undefined;
  }

  switch (toast.type) {
    case "inform":
    case "success":
      return 3000;
  }

  return 5_000;
};

const removeToast = (id: string) => {
  // Ugly hack because we want toasts to transition on close,
  // set this to be longer than the duration configured in the CSS below.
  setTimeout(() => {
    toasts.value = toasts.value.filter((toast) => toast.id !== id);
  }, 150);
};

defineProps<{
  toast: InternalToast;
}>();
</script>

<style lang="scss" scoped>
// These styles don't seem convenient to write in Tailwind.

// We're wrapping it under .scope class because radix-vue doesn't accept
// scoped styling.
.scope {
  :deep(.viewport) {
    --viewport-padding: 24px;
    @apply fixed bottom-0 right-0;
    @apply flex flex-col gap-3;
    @apply m-0;
    padding: var(--viewport-padding);
    width: 400px;
    max-width: 100vw;
    list-style: none;
    z-index: 2147483647;
    outline: none;
  }

  :deep(.toast) {
    &[data-state="open"] {
      animation: slide-in 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state="closed"] {
      animation: hide 100ms ease-in;
    }
    &[data-swipe="move"] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }
    &[data-swipe="cancel"] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }
    &[data-swipe="end"] {
      animation: swipe-out 100ms ease-out;
    }
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipe-out {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}
</style>
