// Based on https://github.com/prazdevs/pinia-plugin-persistedstate
// We don't need a lot of things from it, so here is a barebones implementation.

// This plugin doesn't handle broadcasting to other tabs, this responsibility
// is currently done by `pinia-shared-state`

import { PiniaPlugin, StateTree, Store } from "pinia";

const PREFIX = "pinia:";

export interface PersistenceOptions<S extends StateTree> {
  validate?: (state: StateTree) => state is S;
}

declare module "pinia" {
  // eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
  export interface DefineStoreOptionsBase<S extends StateTree, Store> {
    persist?: PersistenceOptions<S>;
  }

  export interface PiniaCustomProperties {
    $persist: () => void;
  }
}

export const createPiniaPersister = (): PiniaPlugin => {
  return ({ pinia, store, options: { persist } }) => {
    if (!persist) {
      return;
    }

    const id = store.$id;
    const storageKey = PREFIX + id;

    // HMR handling, ignore stores created as 'hot' stores
    if (!(id in pinia.state.value)) {
      // @ts-expect-error `_s` is a stripped @internal
      const originalStore: Store = pinia._s.get(id.replace("__hot:", ""));
      if (originalStore) {
        Promise.resolve().then(() => originalStore.$persist());
      }

      return;
    }

    store.$persist = () => {
      try {
        const raw = JSON.stringify(store.$state);
        localStorage.setItem(storageKey, raw);
      } catch (err) {
        console.error(`[pinia-persist]: failed to persist ${id}`, err);
      }
    };

    // Hydrate the store
    try {
      const persisted = localStorage.getItem(storageKey);
      if (persisted) {
        const json = JSON.parse(persisted);
        const valid = persist.validate?.(json) ?? true;

        if (valid) {
          store.$patch(json);
        } else {
          console.warn(`[pinia-persist]: invalid hydration for ${id}`);
        }
      }
    } catch (err) {
      console.error(`[pinia-persist]: failed to hydrate ${id}`, err);
    }

    // Subscribe to the store
    store.$subscribe(() => store.$persist(), { detached: true });
  };
};
