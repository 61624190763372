<template>
  <Dialog
    v-if="state.status !== 'not_started'"
    :title="`Export ${collections[0]}?`"
    @close="state = { status: 'not_started' }"
  >
    <div v-if="state.status !== 'done' && hasFiltersApplied" class="-mx-4 mb-4">
      <Callout variant="warning">
        This export will have your current filters applied.
      </Callout>
    </div>

    <p v-if="state.status === 'confirming'">
      Are you sure you want to export
      {{ props.count }}
      {{ props.count == 1 ? collections[0]?.slice(0, -1) : collections[0] }}?
    </p>
    <p v-else-if="state.status === 'pending'">
      Exporting... (We'll email you the export when it's ready, so feel free to
      close this dialog.)
    </p>
    <p v-else-if="state.status === 'done'">
      Done! We've emailed you the export, and you can download it below.
    </p>

    <template #actions>
      <ActionButton
        v-if="state.status !== 'done'"
        :disabled="state.status !== 'confirming'"
        :pending="state.status === 'pending'"
        text="Export"
        variant="primary"
        @click="process(props.parameters || {})"
      />
      <ActionButton
        v-else
        text="Download"
        icon="document-arrow-down"
        variant="primary"
        :href="state.url"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, toRef } from "vue";

import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import ActionButton from "@/components/Utilities/ActionButton.vue";
import Callout from "@/design_system/Callout.vue";
import Dialog from "@/design_system/Dialog/Dialog.vue";

import { useExport } from "./lib";

const props = defineProps<{
  exporter: ReturnType<typeof useExport>;
  count: number;
  parameters?: Parameter<string>;
}>();

const exporter = props.exporter;

const { collections, process } = exporter;
const state = toRef(exporter, "state");

const hasFiltersApplied = computed((): boolean => {
  const parameters = props.parameters;

  return (
    !!parameters &&
    Object.values(parameters).filter((v) => v && v.length > 0).length > 0
  );
});
</script>
