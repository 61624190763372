<!--
  This component is a workaround for the lackluster nested drawer behavior.
  Rather than spending more time trying to make this work, let's just show
  nested menus for desktop only.
-->
<template>
  <slot v-if="context.isMobile" name="mobile"></slot>
  <slot v-else name="desktop"></slot>
</template>

<script setup lang="ts">
import { inject } from "./context";

const context = inject();

defineSlots<{
  mobile(props: {}): void;
  desktop(props: {}): void;
}>();
</script>
