<template>
  <button
    type="button"
    :class="[
      'flex gap-1 items-center px-2 py-1',
      'select-none whitespace-nowrap text-sm font-medium no-underline',
      'rounded-md',
      props.variant === 'default' &&
        'border border-gray-200 bg-gray-100 text-gray-700 transition-colors hover:bg-gray-200',
      props.variant === 'secondary' && 'text-gray-400 hover:text-white',
    ]"
  >
    <span>
      <slot></slot>
    </span>

    <ChevronDownIcon class="size-4 shrink-0" />
  </button>
</template>

<script setup lang="ts">
import ChevronDownIcon from "@/icons/heroicons/chevron-down-micro.svg";

const props = withDefaults(
  defineProps<{
    variant?: "default" | "secondary";
  }>(),
  {
    variant: "default",
  }
);
</script>
