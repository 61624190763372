import { inject, InjectionKey } from "vue";

export interface ControlRegisterOptions {
  multiple?: boolean;
}

export interface SettingContext {
  register(opts?: ControlRegisterOptions): string | undefined;
}

export const SETTING_CONTROL_KEY: InjectionKey<SettingContext> =
  Symbol("setting.control");

export const useSettingControl = (): SettingContext | undefined => {
  return inject(SETTING_CONTROL_KEY);
};
