import { defineStore } from "pinia";
import { getCurrentInstance, onMounted } from "vue";

import { components, operations } from "@/autogen/internal-openapi";
import URLS from "@/autogen/urls";
import internalApi from "@/lib/api/internal-base";
import { ok } from "@/lib/openapi";
import whenNewsletterChanges from "@/lib/whenNewsletterChanges";

import {
  type List,
  type ListResource,
  useAggregate,
  useListResource,
  useListUpdateResource,
} from "./base";
import { constructInitialParameters } from "./utils";

export const updateResourceOnMount = <M, O, R extends ListResource<M, O, any>>(
  resource: R
): R => {
  const currentInstance = getCurrentInstance();
  if (currentInstance) {
    onMounted(() => {
      resource.list();
    }, currentInstance);
  }
  return resource;
};

export type ILazyInitializer<T> = () => T;

export class Lazy<T> {
  private instance: T | null = null;
  private initializer: ILazyInitializer<T>;

  constructor(initializer: ILazyInitializer<T>) {
    this.initializer = initializer;
  }

  public get value(): T {
    if (this.instance == null) {
      this.instance = this.initializer();
    }

    return this.instance;
  }
}

export const compose = <T>(
  obj: T
): {
  then: (next: ((arg: T) => T)[]) => T;
} => {
  return {
    then: (next: ((arg: T) => T)[]) => {
      return next.reduce((acc, n) => n(acc), obj);
    },
  };
};

export const updateResourceOnNewsletterChange = <
  M,
  O,
  R extends ListResource<M, O, any>
>(
  resource: R
): R => {
  whenNewsletterChanges(() => {
    resource.list();
  });
  return resource;
};

// TODO: check `retrieve_mentions` for `expand` and `ordering` parameters
type MentionParameters =
  /* operations["retrieve_mentions"]["parameters"]["query"] & */ {
    email?: string[];
    source?: string[];
    // TODO: is this correct? `retrieve_mentions` does `creation_date__start`
    creation_date?: string[];
    expand?: string[];
    ordering?: string[];
  };

const constructMentionResource = () => {
  const resource = useListResource(
    async (params: MentionParameters, { signal }) => {
      const data = await ok(
        internalApi.get("/mentions", {
          signal,
          params: {
            // @ts-expect-error
            query: params,
          },
        })
      );

      return data;
    },
    {
      expand: ["subscriber", "automation", "email"],
      ...constructInitialParameters(
        "/mentions",
        "mentionParameters",
        ["source", "creation_date"],
        []
      ),
    }
  );

  const { fieldToAggregateCount, fieldToGlobalAggregateCount, aggregate } =
    useAggregate(URLS.aggregate("mention"));

  return {
    ...resource,
    fieldToAggregateCount,
    fieldToGlobalAggregateCount,
    aggregate,
  };
};

const constructEndpoint = <
  M,
  O,
  P extends {
    ordering?: O;
  }
>(
  obj: List<M, P>
): Lazy<ListResource<M, O, P>> =>
  new Lazy(() =>
    compose(useListResource(obj)).then([updateResourceOnNewsletterChange])
  );

type ConversationParameters =
  operations["retrieve_conversations"]["parameters"]["query"];

type ConversationInput =
  operations["update_conversation"]["parameters"]["path"] &
    components["schemas"]["ConversationUpdateInput"];

export const useStore = defineStore(
  "internal-openapi-mirror",
  () => {
    return {
      "/invoices": constructEndpoint(async (_params: {}, { signal }) => {
        const data = await ok(internalApi.get("/invoices", { signal }));
        return data;
      }),
      "/subscriber-imports": constructEndpoint(
        async (_params: {}, { signal }) => {
          const data = await ok(
            internalApi.get("/subscriber-imports", { signal })
          );
          return data;
        }
      ),
      "/mentions": new Lazy(() =>
        compose(constructMentionResource()).then([
          updateResourceOnNewsletterChange,
        ])
      ),
      "/products": constructEndpoint(async (_params: {}, { signal }) => {
        const data = await ok(internalApi.get("/products", { signal }));
        return data;
      }),
      "/subscriptions": constructEndpoint(async (_params: {}, { signal }) => {
        const data = await ok(internalApi.get("/subscriptions", { signal }));
        return data;
      }),
      "/utm-sources": constructEndpoint(async (_params: {}, { signal }) => {
        const data = await ok(internalApi.get("/utm-sources", { signal }));
        return data;
      }),
      "/referrer-urls": constructEndpoint(async (_params: {}, { signal }) => {
        const data = await ok(internalApi.get("/referrer-urls", { signal }));
        return data;
      }),
      "/conversations": new Lazy(() =>
        compose(
          useListUpdateResource(
            async (params: ConversationParameters, { signal }) => {
              const data = await ok(
                internalApi.get("/conversations", {
                  signal,
                  params: {
                    query: params,
                  },
                })
              );

              return data;
            },
            async (input: ConversationInput, { signal }) => {
              const { conversation_id, ...body } = input;

              const data = await ok(
                internalApi.patch("/conversations/{conversation_id}", {
                  signal,
                  params: {
                    path: { conversation_id },
                  },
                  body: body,
                })
              );

              return data;
            },
            {},
            {
              expand: ["subscriber", "automation", "email"],
            }
          )
        ).then([updateResourceOnMount, updateResourceOnNewsletterChange])
      ),
    };
  },
  {
    broadcast: {},
  }
);
