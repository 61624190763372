<template>
  <Dialog
    v-if="showingModal"
    :title="activeNotification.headline"
    @close="showingModal = false"
  >
    <div
      class="[&_ul]:list-disc [&_ul]:ml-4"
      v-html="activeNotification.description"
    ></div>

    <template #actions>
      <ActionButton
        text="Got it!"
        variant="primary"
        icon="check"
        @click="notificationsStore.markAsRead(activeNotification)"
      />
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import ActionButton from "@/components/Utilities/ActionButton.vue";
import Dialog from "@/design_system/Dialog/Dialog.vue";
import { useStore } from "@/store/stores/notifications";
import { Notification } from "@/types/notification";

const props = defineProps<{
  notifications: Notification[];
}>();

const activeNotification = computed<Notification>(() => {
  return props.notifications[0];
});

const showingModal = ref(true);

const notificationsStore = useStore();

watch(
  () => props.notifications.length,
  (newLength: number) => {
    showingModal.value = newLength > 0;
  },
  { immediate: true }
);
</script>
