<template>
  <BulkActionModal
    :selection="bulkAction.selection"
    :action="bulkAction.action_type"
    :noun="noun"
    :verb="config.verb"
    :icon="icon"
    :list="config.list"
    :payload="config.payload || {}"
    :count="count"
    :header="config?.header"
    :parameters="genericParameters"
    :disabled="disabled"
    :on-success="handleSuccess"
  >
    <template #body="{ parameters, data: subscribers }">
      <div v-if="bulkAction.action_type === 'delete_emails'">
        <div
          v-if="
            bulkAction.selection.mode === 'all' &&
            JSON.stringify(parameters) ===
              JSON.stringify({
                status: [],
                email_type: [],
                source: [],
                automation: [],
              })
          "
        >
          <p class="mb-2">
            You'll be deleting <strong>all</strong> emails, including drafts and
            published emails.
          </p>
        </div>
        <div
          v-else-if="
            bulkAction.selection.mode === 'all' &&
            parameters !== undefined &&
            Object.values(parameters).some((value) => value.length > 0)
          "
        >
          <p>You'll be deleting all emails that match the following filters:</p>
          <ParameterTable :parameters="parameters" />
        </div>
        <div
          v-if="
            emails &&
            emails.length === 1 &&
            emails[0].status === 'about_to_send'
          "
        >
          This email is about to be sent out. Are you sure you want to delete
          it?
        </div>
        <div
          v-else-if="
            emails && emails.length === 1 && emails[0].status === 'in_flight'
          "
        >
          This email is currently being sent out. Deleting it will halt that
          process, but some subscribers have already received it.
        </div>
        <div
          v-else-if="
            emails && emails.length === 1 && emails[0].status === 'draft'
          "
        >
          This email is a draft; you won't be able to recover it.
        </div>
        <div v-else-if="emails && emails.length === 1">
          Deleting this email will remove it completely from your newsletter,
          and links pointing to the email will be broken.
        </div>
        <p v-else>
          Deleting these emails will remove them completely from your
          newsletter, and links pointing to the emails will be broken.
        </p>
      </div>
      <div v-else-if="bulkAction.action_type === 'delete_tags'">
        <p v-if="subscribers && subscribers.length === 0">
          Deleting this tag will <strong>not</strong> delete any subscribers or
          emails associated with the tag; they will simply have the tag removed.
        </p>
        <p v-else-if="subscribers && subscribers.length === 1">
          Deleting this tag will <strong>not</strong> delete any subscribers or
          emails associated with the tags; they will simply have the tag
          removed.
        </p>
        <p v-else>
          Deleting these tags will <strong>not</strong> delete any subscribers
          or emails associated with the tags; they will simply have the tag
          removed.
        </p>
      </div>
      <div v-else-if="bulkAction.action_type === 'apply_tags'">
        <CheckboxGroup
          v-model="tag_ids"
          :options="
            tags.map((t) => {
              return {
                id: t.id,
                name: t.name,
              };
            })
          "
        />
      </div>
      <div v-else-if="bulkAction.action_type === 'gift_subscribers'">
        <Setting>
          <template #header>Message</template>
          <template #description>
            You can set a message that will be sent to the subscriber alongside
            their gift.
          </template>

          <LongTextInput v-model="message" />
        </Setting>

        <Setting>
          <template #header>End date</template>
          <template #description>
            You can set a duration for the gift subscription. If you don't set a
            duration, the subscription will last forever.
          </template>

          <div class="-mx-3">
            <DateInput v-model="date" label="Date" />
          </div>
        </Setting>
      </div>
      <div v-else-if="bulkAction.action_type === 'send_emails'">
        <select
          v-model="email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option v-for="e in emails" :key="e.id" :value="e">
            {{ e.subject }}
          </option>
        </select>
      </div>
      <div
        v-else-if="
          bulkAction.selection.mode === 'all' &&
          parameters !== undefined &&
          Object.values(parameters).some((value) => value.length > 0)
        "
      >
        <p>
          You'll be {{ config.gerund }} all subscribers that match the following
          filters:
        </p>
        <ParameterTable :parameters="parameters" />
      </div>
      <div
        v-else-if="subscribers && subscribers.length > 0"
        class="text-gray-700 leading-normal space-y-4"
      >
        <p v-if="bulkAction.action_type === 'mark_subscribers_as_not_spammy'">
          Are you sure? Marking
          <span v-if="subscribers.length === 1">this subscriber</span>
          <span v-else>these subscribers</span>
          will restore their original status. If they're marked as spammy again,
          your account will be flagged for suspicious activity; please only use
          this if you're very confident the subscription is legit.
        </p>
        <p v-if="bulkAction.action_type === 'resubscribe_subscribers'">
          Are you sure? Resubscribing
          <span v-if="subscribers.length === 1">this subscriber</span>
          <span v-else>these subscribers</span>
          will add them back to the newsletter. If this subscriber unsubscribes
          again, your account will be flagged for suspicious activity; please
          only use this if you're very confident the unsubscription was in
          error.
        </p>
        <div v-if="bulkAction.action_type === 'reactivate_subscribers'">
          <p>
            Please be absolutely sure your subscriber is aware that you're
            reactivating their subscription!
          </p>
        </div>
        <div v-if="bulkAction.action_type === 'send_reminders'">
          Are you sure? Please make sure you've given this subscriber adequate
          time to respond and read your email; otherwise, you may receive a
          complaint or spam notice from them, harming your deliverability.
        </div>
        <div
          v-if="
            bulkAction.action_type === 'delete_subscribers' ||
            bulkAction.action_type === 'ban_subscribers' ||
            bulkAction.action_type === 'unsubscribe_subscribers'
          "
        >
          <div
            v-if="
              subscribers &&
              subscribers.length === 1 &&
              subscribers[0].type === 'premium'
            "
            class="-mx-4 mb-4"
          >
            <Callout variant="warning">
              Because this subscriber is a premium subscriber, their paid
              subscription will also cease. They will not be refunded; if you
              want to give them a refund, you'll want to do so by going through
              your Stripe dashboard.
            </Callout>
          </div>
          <p v-if="bulkAction.action_type === 'ban_subscribers'">
            Are you sure? Banning
            <span v-if="subscribers.length === 1">this subscriber</span>
            <span v-else>these subscribers</span>
            will remove them completely from your newsletter, and they will be
            unable to resubscribe.
          </p>
          <p v-if="bulkAction.action_type === 'unsubscribe_subscribers'">
            Are you sure? Unsubscribing
            <span v-if="subscribers.length === 1">this subscriber</span>
            <span v-else>these subscribers</span>
            will remove them completely from your newsletter. They will be able
            to resubscribe if they wish.
          </p>
          <p v-if="bulkAction.action_type === 'delete_subscribers'">
            Deleting
            <span v-if="subscribers.length === 1">this subscriber</span>
            <span v-else>these subscribers</span>
            will remove all record of them from Buttondown. They will be able to
            resubscribe if they wish.
          </p>
        </div>
      </div>
    </template>
  </BulkActionModal>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import BulkActionModal from "@/components/BulkActionModal/Container.vue";
import { generateBulkActionConfiguration } from "@/components/BulkActionModal/lib";
import ParameterTable from "@/components/Layout/ListView/ParameterTable.vue";
import CheckboxGroup from "@/components/Utilities/Forms/CheckboxGroup.vue";
import Callout from "@/design_system/Callout.vue";
import {
  CreateParams,
  useStore as useBulkActionsStore,
} from "@/store/stores/bulk_actions";
import { useStore as useEmailsStore } from "@/store/stores/emails";
import { useStore as useOpenAPIMirrorStore } from "@/store/stores/openapi-mirror";
import { useStore as useSubscribersStore } from "@/store/stores/subscribers";

import Setting from "../Setting/Setting.vue";
import DateInput from "../Utilities/Forms/DateInput.vue";
import LongTextInput from "../Utilities/Forms/LongTextInput.vue";

const props = defineProps<{
  bulkAction: CreateParams;
}>();

const config = computed(
  () =>
    generateBulkActionConfiguration({
      tag_ids: tag_ids.value,
      bulkAction: props.bulkAction,
      email: email.value,
      store: store,
      emailsStore: emailsStore,
      date: date.value,
      message: message.value,
    })[props.bulkAction.action_type]
);

const bulkActionsStore = useBulkActionsStore();
const emailsStore = useEmailsStore();
const subscribersStore = useSubscribersStore();

// These represent possible payloads for the bulk action.
const email = ref<OpenAPI["schemas"]["Email"] | null>(null);
const tag_ids = ref<string[]>([]);
const message = ref("");
const date = ref<string | null>(null);

const emails = computed(() =>
  emailsStore.resource.filter(
    (email) => email.status === "sent" || email.status === "imported"
  )
);

const store = useOpenAPIMirrorStore();
const tags = computed(() => store["/tags"].value.resource);
const subscriberCount = computed(() => subscribersStore.count);
const subscriberParameters = computed(() => subscribersStore.parameters);

const count = computed(() => {
  return config.value.count ?? subscriberCount.value;
});

const genericParameters = computed(() => {
  return config.value.parameters ?? subscriberParameters.value;
});

const icon = computed(() => config.value?.icon || "question-mark-circle");
const noun = computed(() => config.value?.noun || "subscriber");

const disabled = computed(() => {
  return config.value.disabled || false;
});

const handleSuccess = computed(() => {
  return () => {
    bulkActionsStore.setDraft(null);
    return (
      config.value.handleSuccess ||
      (() => {
        subscribersStore.list();
      })
    )();
  };
});
</script>
