<template>
  <td
    :id="rowProps && primary ? rowProps.titleId : undefined"
    :class="{
      'leading-6 lg:table-cell p-0 whitespace-nowrap truncate tabular-nums first:pl-4': true,
      hidden: !primary,
      'pr-4': theme !== 'dense',
      'pr-2': theme === 'dense',
      'font-bold w-full': primary,
    }"
    :colspan="colspan"
  >
    <slot />
  </td>
</template>

<script lang="ts" setup>
import { inject } from "./context";

const rowProps = inject(null);

defineProps<{
  primary?: boolean;
  colspan?: number;
  theme?: "dense";
}>();
</script>
