import { ref } from "vue";

import { components as OpenAPI } from "@/autogen/openapi";
import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import api from "@/lib/api/base";
import { ok } from "@/lib/openapi";
import { useStore } from "@/store/stores/notifications";
import { uuid } from "@/utils";

import { removeToastById, showToast } from "../toasts";

const TIMEOUT_BEFORE_SHOWING_POPUP_IN_MILLISECONDS = 300 * 1000;

const pollStatus = async (id: string, callback?: () => void) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const data = await ok(
      api.get("/exports/{id}", {
        params: { path: { id } },
      })
    );

    if (data.status === "ready") {
      showToast({
        type: "success",
        title: `Export successful!`,
      });

      if (data.url) {
        window.location.href = data.url;
      }
      if (callback) {
        callback();
      }

      break;
    }

    await new Promise((resolve) => setTimeout(resolve, 1_000));
  }
};

type State =
  | {
      status: "not_started" | "confirming" | "pending";
    }
  | {
      status: "done";
      url: string;
    };

export const useExport = (
  collections: [OpenAPI["schemas"]["ExportCollection"]] | []
) => {
  const state = ref<State>({ status: "not_started" });
  const notificationsStore = useStore();

  const confirm = () => {
    if (collections.length === 0) {
      return;
    }

    state.value = { status: "confirming" };
  };

  const process = async (parameters: Parameter<string>) => {
    if (collections.length === 0) {
      return;
    }

    const pendingToastId = showToast({
      type: "pending",
      title: `Exporting`,
    });

    state.value = { status: "pending" };

    const data = await ok(
      api.post("/exports", {
        body: { collections, parameters },
      })
    );

    if (data.status === "ready") {
      if (data.url) {
        state.value = { status: "done", url: data.url };

        removeToastById(pendingToastId);

        showToast({
          type: "success",
          title: `Export successful!`,
        });
      }

      return;
    }

    const timeout = setTimeout(() => {
      notificationsStore.notifications.push({
        id: uuid(),
        headline: "Your export is taking longer than expected.",
        description:
          "Sorry about that! The system is under load right now (it's an 'us' problem, not a 'you' problem.) We'll email you the export when it's ready; you can close this page.",
        route: null,
        creation_date: new Date().toISOString(),
      });
    }, TIMEOUT_BEFORE_SHOWING_POPUP_IN_MILLISECONDS);

    pollStatus(data.id, () => {
      if (data.url) {
        state.value = { status: "done", url: data.url };

        removeToastById(pendingToastId);

        showToast({
          type: "success",
          title: `Export successful!`,
        });
      }

      clearTimeout(timeout);
    });
  };

  return {
    collections,

    process,
    confirm,

    get state() {
      return state.value;
    },
    set state(next) {
      state.value = next;
    },
  };
};
