<template>
  <Dialog
    :title="title"
    @close="
      () => {
        setDraft(null);
        if (parentRouteName) router.push(parentRouteName);
      }
    "
  >
    <div v-if="exceededMaxIds">
      <p class="text-red-600 text-center text-sm font-medium">
        Unfortunately, you can’t execute changes on more than 200 records at a
        time.
      </p>
    </div>
    <div v-else>
      <!-- We include this check because otherwise, data is null which child components
        will interpret as "delete _everything_". This isn't a problem from a server-side standpoint,
     but is very scary if authors see a flash that says "You're about to delete all emails!" or whatever. -->
      <div v-if="!loading">
        <slot name="body" :data="data" :parameters="props.parameters" />
      </div>
    </div>

    <template v-if="!exceededMaxIds" #actions>
      <ActionButton
        :text="
          verb.endsWith('to')
            ? verb.replace(' to', '')
            : verb.endsWith('from')
            ? verb.replace(' from', '')
            : verb
        "
        :icon="icon"
        hotkey="Enter"
        :disabled="mutationStatus === 'loading' || props.disabled"
        :pending="mutationStatus === 'loading'"
        @click="act"
      />
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import ActionButton from "@/components/Utilities/ActionButton.vue";
import Dialog from "@/design_system/Dialog/Dialog.vue";
import { type MappedIconMicro } from "@/icons/icon-mapping-micro";
import useQuery from "@/lib/query";
import router from "@/router";
import { RouteLocation } from "@/router/types";
import { useStore as useBulkActionsStore } from "@/store/stores/bulk_actions";

import { Selection } from "../Layout/ListView/lib";

const props = defineProps<{
  selection: Selection<{ id: string }>;
  action: OpenAPI["schemas"]["BulkActionType"];
  verb: string;
  noun: string;
  icon: MappedIconMicro;
  parentRouteName?: RouteLocation;
  list?: (ids: string[]) => Promise<any[]>;
  count?: number;
  parameters?: Parameter<any>;
  payload: { [key: string]: any };
  disabled?: boolean;
  onSuccess?: () => void;
  header?: (item: any) => string;
}>();

const bulkActionsStore = useBulkActionsStore();

const setDraft = bulkActionsStore.setDraft;

const mutationStatus = computed(() =>
  bulkActionsStore.isCreatingBulkAction ? "loading" : "ready"
);

const MAXIMUM_LISTING_ID_COUNT = 10;

const { loading, data, execute } = useQuery(() => {
  if (props.selection.mode === "all") {
    return Promise.resolve([]);
  }
  if (props.selection.items.length > MAXIMUM_LISTING_ID_COUNT) {
    return Promise.resolve([]);
  }
  return props.list
    ? props.list(props.selection.items.map((item) => item.id))
    : Promise.resolve([]);
});

onMounted(() => {
  execute();
});

const BULK_ACTION_MAX_IDS = 200;
const exceededMaxIds = computed(() => {
  if (props.selection.mode === "all") {
    return false;
  }
  return props.selection.items.length > BULK_ACTION_MAX_IDS;
});

const title = computed(() => {
  const items = data.value;

  if (items && items.length === 1 && items[0]) {
    const head = props.header;

    return `${props.verb} ${head ? head(items[0]) : items[0].id}?`;
  }

  if (items?.length) {
    return `${props.verb} ${items.length} ${props.noun}s?`;
  }

  if (
    props.count &&
    (props.selection.mode === "all" ||
      (props.selection.items.length === 1 &&
        props.selection.items[0].id === "all"))
  ) {
    return `${props.verb} ${props.count} ${props.noun}s?`;
  }

  if (
    props.count &&
    props.selection.mode !== "all" &&
    props.selection.items.length === 1
  ) {
    return `${props.verb} one ${props.noun}?`;
  }

  if (props.count && props.selection.mode !== "all") {
    return `${props.verb} ${props.selection.items.length} ${props.noun}s?`;
  }

  return "Loading...";
});

const act = async () => {
  await bulkActionsStore.createBulkAction({
    data: {
      selection: props.selection,
      action_type: props.action,
      ...props.payload,
      parameters: props.parameters,
    },
    onSuccess: () => {
      if (props.onSuccess) {
        props.onSuccess();
      }
    },
  });
  if (props.parentRouteName) {
    router.push(props.parentRouteName);
  }
};
</script>
