<template>
  <transition
    enter-active-class="duration-200 ease-out"
    enter-from-class="transform opacity-0 translate-y-10"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0 translate-y-10"
  >
    <div
      class="@container absolute bottom-[20px] left-[20px] right-[20px] z-50 flex items-center justify-between gap-2 rounded border border-gray-300 bg-gray-200 px-2 py-2 text-sm font-bold text-gray-600 drop-shadow-lg transition"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script lang="ts" setup></script>
