import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router/auto";
import { routes } from "vue-router/auto-routes";

import { useStore } from "@/store/stores/scaffolding";
import { Account } from "@/types/account";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

type RouterOutcome = "LOGIN" | "EMAILS";

const UNAUTHED_ROUTES = ["/login", "/register"];

const getRouterOutcome = (
  to: RouteLocationNormalized
): RouterOutcome | null => {
  const store = useStore();
  const account = store.account as Account | null;
  const isLoggedIn = account?.id !== null && account?.id !== undefined;

  if (
    to.matched.some((record) => !UNAUTHED_ROUTES.includes(record.path)) &&
    !isLoggedIn
  ) {
    return "LOGIN";
  }

  if (
    to.matched.some((record) => UNAUTHED_ROUTES.includes(record.path)) &&
    isLoggedIn
  ) {
    return "EMAILS";
  }
  return null;
};

router.beforeEach((to, _, next) => {
  const outcome = getRouterOutcome(to);
  if (outcome === "LOGIN") {
    next({
      path: "/login",
      query: { next: to.path },
    });
  } else if (outcome === "EMAILS") {
    next({
      name: "/emails",
    });
  }
  next();
});

export default router;
