<template>
  <div
    class="sticky top-0 z-30 flex h-[53px] shrink-0 items-center justify-between gap-4 border-b border-gray-300 bg-white px-4"
  >
    <div class="flex items-center gap-2 overflow-hidden">
      <button
        v-if="!showingSidebar && !props.hideSidebar"
        type="button"
        aria-label="Toggle sidebar"
        class="-m-0.5 rounded-lg p-0.5 text-gray-600 hover:bg-gray-200 hover:text-gray-800"
        @click="toggleSidebar"
      >
        <Bars3Icon class="size-5" />
      </button>

      <div class="hidden text-sm font-bold lg:contents">
        <template v-for="(breadcrumb, idx) in props.breadcrumbs" :key="idx">
          <RouterLink
            v-if="
              breadcrumb.state === 'route' &&
              breadcrumb.record &&
              idx !== props.breadcrumbs.length - 1
            "
            :to="breadcrumb.record"
            class="-mx-1.5 -my-1 whitespace-nowrap rounded-lg px-1.5 py-1 text-gray-400 hover:bg-gray-200 hover:text-gray-800"
          >
            {{ breadcrumb.text }}
          </RouterLink>
          <span
            v-else-if="breadcrumb.state === 'pending'"
            class="h-5 w-20 animate-pulse rounded-md bg-slate-200"
          ></span>
          <span
            v-else
            class="whitespace-nowrap text-black overflow-hidden text-ellipsis"
          >
            {{ breadcrumb.text }}
          </span>

          <span
            v-if="idx !== props.breadcrumbs.length - 1"
            class="hidden whitespace-nowrap text-gray-400 lg:inline"
          >
            /
          </span>
        </template>
      </div>

      <slot />
    </div>

    <div class="flex items-center gap-2">
      <slot name="actions" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";

import Bars3Icon from "@/icons/heroicons/bars-3-outline.svg";
import { type RouteLocation } from "@/router/types";
import { useStore } from "@/store/stores/scaffolding";

export type Breadcrumb =
  | { state: "route"; text: string; record?: RouteLocation }
  | { state: "pending" };

export type Props = {
  breadcrumbs: Breadcrumb[];
  hideSidebar?: boolean;
};

const props = defineProps<Props>();

const store = useStore();
const showingSidebar = computed(() => store.showingSidebar);
const toggleSidebar = store.toggleSidebar;

watch(
  () => props.breadcrumbs,
  () => {
    if (props.breadcrumbs?.[0]?.state === "route") {
      window.document.title = `${props.breadcrumbs[0].text} · Buttondown`;
    }
  },
  { immediate: true }
);
</script>
