<template>
  <button
    type="button"
    class="flex w-full cursor-pointer items-center space-x-2 rounded-full border border-gray-300 bg-white px-3 py-1 text-left text-lg transition-colors hover:border-gray-400 lg:text-sm"
    @click="emit('click')"
  >
    <MagnifyingGlassIcon class="size-4" />
    <div class="flex-1">Search</div>
    <div
      class="text-xs border px-[5px] py-[1px] rounded-lg border-gray-300 !-mr-2 hidden lg:block"
    >
      {{ formatKeybind("$mod+k") }}
    </div>
  </button>
</template>

<script lang="ts" setup>
import MagnifyingGlassIcon from "@/icons/heroicons/magnifying-glass-micro.svg";
import { formatKeybind } from "@/lib/hotkeys";

const emit = defineEmits(["click"]);
</script>
