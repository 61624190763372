<template>
  <ActionsWidget class="gap-4">
    <div class="shrink-0 flex items-center gap-4 pl-2">
      <Checkbox
        :id="id"
        v-model="fullSelection"
        :indeterminate="indeterminate"
      />

      <label :for="id" class="mb-0 whitespace-nowrap">
        <template v-if="selection.mode === 'all'">
          {{ count }} {{ noun }} selected
        </template>
        <template v-else-if="selection.mode === 'all_except' && count">
          {{ count - selection.items.length }} {{ noun }} selected
        </template>
        <template v-else>
          {{ selection.items.length }} selected
          {{ selection.items.length === 1 ? singularNoun : noun }}
        </template>
      </label>

      <button
        v-if="
          selection.mode === 'some' &&
          selection.items.length === items.length &&
          count &&
          items.length < count
        "
        type="button"
        class="text-blue-500 hover:underline"
        @click="emit('selectEverything', $event)"
      >
        Select all {{ count }} {{ noun }}
      </button>
    </div>

    <div ref="scrollerRef" class="flex grow justify-end gap-2 overflow-x-auto">
      <SelectionWidgetMenuItem
        v-for="action in actions"
        :key="action.id"
        ref="nodeRefs"
        :icon="action.icon"
        :text="action.label"
        :hotkey="action.hotkey"
        :disabled="action.disabled"
        :to="action.to"
        @click="action.onClick"
      />

      <Tooltip.Hint v-if="overflown" text="More actions">
        <Menu.Root>
          <Menu.Trigger>
            <button
              type="button"
              class="h-8 w-8 rounded-md bg-gray-600 text-white grid place-items-center hover:bg-gray-700"
            >
              <EllipsisHorizontalIcon class="h-4 w-4" />
            </button>
          </Menu.Trigger>

          <Menu.Container>
            <template v-for="item of overflown" :key="item.id">
              <Menu.RouteItem
                v-if="item.to"
                :disabled="item.disabled"
                :to="item.to"
                :icon="item.icon"
                :label="item.label"
                @click="item.onClick"
              />
              <Menu.Item
                v-else
                :disabled="item.disabled"
                :icon="item.icon"
                :label="item.label"
                @click="item.onClick"
              />
            </template>
          </Menu.Container>
        </Menu.Root>
      </Tooltip.Hint>
    </div>
  </ActionsWidget>
</template>

<script setup lang="ts" generic="Model extends { id: string }">
import {
  computed,
  onWatcherCleanup,
  reactive,
  ref,
  shallowRef,
  useId,
  watch,
} from "vue";

import Checkbox from "@/components/Utilities/Forms/Checkbox.vue";
import Menu from "@/design_system/Menu";
import Tooltip from "@/design_system/Tooltip";
import EllipsisHorizontalIcon from "@/icons/heroicons/ellipsis-horizontal-micro.svg";
import { throttle } from "@/utils";

import ActionsWidget from "./ActionsWidget.vue";
import { Selection } from "./lib";
import SelectionWidgetMenuItem from "./SelectionWidgetMenuItem.vue";
import { ExposedSelectionWidgetItem, SelectionAction } from "./types";

const emit = defineEmits<{
  clear: [];
  selectAll: [];
  selectEverything: [event: MouseEvent];
}>();

const props = defineProps<{
  items: Model[];
  selection: Selection<Model>;
  count?: number;
  noun: string;
  actions: SelectionAction[];
}>();

const id = useId();

const scrollerRef = ref<HTMLElement | null>(null);
const nodeRefs = reactive<ExposedSelectionWidgetItem[]>([]);

const overflown = shallowRef<SelectionAction[]>();

const OVERFLOW_BUTTON_WIDTH = 32;
const SPACING_GAP = 8;

const measure = () => {
  const nodes = nodeRefs
    .map((reference) => reference.node)
    .filter((node): node is HTMLElement => node !== null);

  const length = nodes.length;
  const totalWidth = scrollerRef.value!.clientWidth;

  let idx = 0;
  let accumulatedWidth = OVERFLOW_BUTTON_WIDTH;

  // Unset all `display: none` that we've set in the previous run
  for (; idx < length; idx++) {
    const node = nodes[idx];
    node.style.display = "";
  }

  // Measure the available width
  for (idx = 0; idx < length; idx++) {
    const node = nodes[idx];
    const width = SPACING_GAP + node.clientWidth;

    if (accumulatedWidth + width > totalWidth) {
      break;
    }

    accumulatedWidth += width;
  }

  // Hide the rest
  if (idx < length) {
    overflown.value = props.actions.slice(idx);
  } else {
    overflown.value = undefined;
  }

  for (; idx < length; idx++) {
    const node = nodes[idx];
    node.style.display = "none";
  }
};

// We're only throttling the resize observer here, if we do it on action item
// changes, it looks bad (do it on the subscribers page, we have many actions
// that only shows up on specific subscriber types.)
const throttledMeasure = throttle(measure, 100);

watch(scrollerRef, (scroller) => {
  if (!scroller) {
    return;
  }

  const observer = new ResizeObserver(throttledMeasure);

  onWatcherCleanup(() => observer.disconnect());
  onWatcherCleanup(watch(nodeRefs, measure));

  observer.observe(scroller);
});

const indeterminate = computed(() => {
  const selection = props.selection;
  const items = props.items;

  return (
    selection.mode !== "all" &&
    selection.items.length > 0 &&
    selection.items.length < items.length
  );
});

const singularNoun = computed(() => {
  const noun = props.noun;
  return noun.substring(0, noun.length - 1);
});

const fullSelection = computed({
  get() {
    const selection = props.selection;
    const items = props.items;

    return selection.mode === "all" || items.length === selection.items.length;
  },
  set() {
    const selection = props.selection;
    const items = props.items;

    if (selection.mode === "all" || selection.items.length === items.length) {
      emit("clear");
    } else {
      emit("selectAll");
    }
  },
});
</script>
