<template>
  <td
    v-if="shouldRender"
    :class="[
      `truncate px-2 leading-6 whitespace-nowrap tabular-nums first:pl-4 last:pr-4`,
      primary && `w-full max-w-0 font-bold`,
    ]"
    @click="handleClick"
  >
    <slot></slot>
  </td>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { useMediaQuery } from "@/lib/media_query";

import { inject } from "./context";

const props = defineProps<{
  primary?: boolean;
  alwaysRender?: boolean;
}>();

const rowProps = inject();

const handleClick = (event: MouseEvent) => {
  if (props.primary) {
    rowProps.onModelClicked?.(event);
  }
};

const isDesktop = useMediaQuery("(min-width: 1024px)");
const shouldRender = computed(() => {
  if (props.primary || props.alwaysRender) {
    return true;
  }

  return isDesktop.value;
});
</script>
