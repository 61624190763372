<template>
  <template v-if="canRead">
    <Template.Define>
      <div
        :class="[
          `flex items-start gap-2`,
          !emphasize ? `text-gray-700` : 'text-blue-500',
        ]"
      >
        <component :is="icon" class="mt-0.5 h-6 w-4 shrink-0 lg:h-4" />
        <span
          class="grow overflow-hidden text-ellipsis text-left text-lg font-medium lg:text-sm"
        >
          {{ title }}
        </span>

        <span
          v-if="number"
          :class="[
            `-mr-2 grid h-5 min-w-5 place-items-center rounded-full text-[10px] font-bold transition-colors`,
            !active ? `bg-gray-300 text-gray-700` : `bg-gray-500 text-white`,
          ]"
        >
          {{ number }}
        </span>
      </div>
    </Template.Define>

    <RouterLink v-if="route" :to="route" :class="className" v-bind="$attrs">
      <Template.Use />
    </RouterLink>
    <a v-else-if="url" :href="url" :class="className" v-bind="$attrs">
      <Template.Use />
    </a>
    <button v-else type="button" :class="className" v-bind="$attrs">
      <Template.Use />
    </button>
  </template>
</template>

<script lang="ts" setup>
import { type Component, computed, toRef } from "vue";
import { useRouter } from "vue-router/auto";

import { createReusableTemplate } from "@/lib/composables/template";
import { RouteLocation } from "@/router/types";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";
import { Permission } from "@/types/account";

const props = defineProps<{
  icon: Component;
  title: string;
  number?: number;
  route?: RouteLocation;
  url?: string;
  permission?: keyof Permission["permissions"];
  emphasize?: boolean;
}>();

const store = useScaffoldingStore();
const router = useRouter();

const Template = createReusableTemplate();

const currentPermissions = computed(() => {
  return store.currentPermissions;
});

const active = computed(() => {
  // @ts-ignore
  const name = props.route?.name as string | undefined;
  if (!name) {
    return false;
  }

  return router.currentRoute.value.path.startsWith(name as string);
});

const canRead = computed(() => {
  if (!props.permission) {
    return true;
  }

  return currentPermissions.value[props.permission] !== undefined;
});

const className = toRef(() => [
  `block px-3 py-1 rounded-full transition-colors`,
  !active.value ? `hover:bg-gray-200` : `bg-gray-300`,
]);
</script>
