<template>
  <div v-if="requiredPlanForFeature === null">
    <slot></slot>
  </div>
  <Dialog
    v-else
    :title="`Upgrade to a ${requiredPlanForFeature.name} plan to access this feature!`"
    variant="very-wide"
    @close="$router.go(-1)"
  >
    <div class="text-center text-lg">
      Ready to commit to your newsletter? Upgrade your subscription to access
      this feature and many more, like:
    </div>
    <div
      class="text-center grid grid-cols-4 gap-4 lg:grid-cols-8 overflow-x-scroll mt-4"
    >
      <div v-for="f in PAID_FEATURES" :key="f.name" class="flex-1">
        <div
          :class="
            'grid place-items-center rounded-lg text-white p-4 mb-2 ' + f.class
          "
        >
          <component :is="f.icon" class="size-6" />
        </div>
        <div class="font-bold">
          {{ f.name }}
        </div>
      </div>
    </div>

    <template #actions>
      <ActionButton
        :href="`/api/emails/upgrade-newsletter?product=${requiredPlanForFeature.id}`"
        text="Unlock this feature"
        icon="bolt"
        variant="primary"
      />
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import Dialog from "@/design_system/Dialog/Dialog.vue";
import ChartBarIcon from "@/icons/heroicons/chart-bar-mini.svg";
import ClipboardIcon from "@/icons/heroicons/clipboard-mini.svg";
import CodeBracketIcon from "@/icons/heroicons/code-bracket-mini.svg";
import GlobeAltIcon from "@/icons/heroicons/globe-alt-mini.svg";
import PaintBrushIcon from "@/icons/heroicons/paint-brush-mini.svg";
import ShoppingBagIcon from "@/icons/heroicons/shopping-bag-mini.svg";
import TagIcon from "@/icons/heroicons/tag-mini.svg";
import UserGroupIcon from "@/icons/heroicons/user-group-mini.svg";
import { Feature } from "@/screens/Billing/lib";
import { useStore } from "@/store/stores/scaffolding";

import ActionButton from "./ActionButton.vue";

const PAID_FEATURES = [
  {
    name: "API",
    description:
      "Integrate your newsletter with other tools, like Zapier, to automate your workflow.",
    class: "bg-red-400",
    icon: CodeBracketIcon,
  },
  {
    name: "Domains",
    description: "Use your own domain name for your newsletter.",
    class: "bg-rose-400",
    icon: GlobeAltIcon,
  },
  {
    name: "Surveys",
    description:
      "Ask your subscribers questions about your newsletter, and get actionable feedback.",
    class: "bg-yellow-500",
    icon: ClipboardIcon,
  },
  {
    name: "Analytics",
    description:
      "See how your newsletter is performing, and make data-driven decisions about your content.",
    class: "bg-amber-400",
    icon: ChartBarIcon,
  },
  {
    name: "CSS",
    description: "Use your own domain name for your newsletter.",
    class: "bg-emerald-400",
    icon: PaintBrushIcon,
  },
  {
    name: "Tags",
    description:
      "Organize your subscribers into groups based on their interests, location, or other criteria.",
    class: "bg-sky-400",
    icon: TagIcon,
  },
  {
    name: "Teams",
    description: "Use your own domain name for your newsletter.",
    class: "bg-blue-400",
    icon: UserGroupIcon,
  },
  {
    name: "Payments",
    description:
      "Charge your subscribers for access to your newsletter, and get paid for your work.",
    class: "bg-purple-400",
    icon: ShoppingBagIcon,
  },
];

const store = useStore();

const props = defineProps<{
  feature: Feature;
}>();

const requiredPlanForFeature = computed(() => {
  return store.calculatePlanRequirement(props.feature);
});
</script>
