import { components as OpenAPI } from "@/autogen/openapi";
import OpenAPIValues from "@/autogen/openapi.json";
import api from "@/lib/api/base";
import { ok } from "@/lib/openapi";

type Page<T> = {
  results: T[];
  count: number;
};

export const listEmails = async (
  baseParams: any
): Promise<Page<OpenAPI["schemas"]["Email"]>> => {
  const data = await ok(
    api.get("/emails", {
      params: {
        query: {
          ...baseParams,
          status:
            !baseParams.status || baseParams.status.length === 0
              ? OpenAPIValues.components.schemas.EmailStatus.enum
              : baseParams.status,
          excluded_fields: ["body"],
        },
      },
    })
  );

  return data;
};
