<template>
  <Template.Define>
    <ArrowPathIcon v-if="pending" class="size-4 animate-spin" />
    <component :is="iconMappingMicro[icon]" v-else-if="icon" class="size-4" />

    <span class="whitespace-nowrap">{{ text + (href ? " ↗" : "") }}</span>

    <span
      v-if="hotkey"
      :class="{
        'hidden lg:block text-xs font-mono uppercase rounded px-1': true,
        'bg-black/50': variant === 'primary' || variant === 'negative',
        'bg-black/15': variant === 'secondary',
      }"
    >
      {{ formatKeybind(hotkey) }}
    </span>
  </Template.Define>

  <a
    v-if="props.href"
    v-bind="$attrs"
    :href="props.href"
    :class="cls"
    target="_blank"
  >
    <Template.Use />
  </a>
  <RouterLink
    v-else-if="props.route"
    v-bind="$attrs"
    :to="props.route"
    :class="cls"
  >
    <Template.Use />
  </RouterLink>
  <button
    v-else
    type="button"
    v-bind="$attrs"
    :class="cls"
    @click="emit('click')"
  >
    <Template.Use />
  </button>
</template>

<script lang="ts" setup>
import { normalizeClass, toRef } from "vue";
import { useRouter } from "vue-router/auto";

import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import {
  type MappedIconMicro,
  iconMappingMicro,
} from "@/icons/icon-mapping-micro";
import { createReusableTemplate } from "@/lib/composables/template";
import { formatKeybind, useKeybinds } from "@/lib/hotkeys";
import { RouteLocation } from "@/router/types";

export type Variant = "primary" | "secondary" | "negative";

const Template = createReusableTemplate();

const router = useRouter();
const props = defineProps<{
  route?: RouteLocation | null;
  href?: string;
  text: string;
  hotkey?: string;
  icon?: MappedIconMicro;
  variant: Variant;
  disabled?: boolean;
  pending?: boolean;
  active?: boolean;
}>();

const emit = defineEmits(["click"]);

const cls = toRef(() => {
  const variant = props.variant;
  const disabled = props.disabled;
  const active = props.active;

  return normalizeClass([
    `relative m-0 flex h-auto items-center space-x-2 rounded-md px-2 py-1 text-sm font-medium no-underline outline-2 outline-blue-500 transition-colors focus-visible:outline`,

    variant === "primary" &&
      `bg-blue-500 text-white outline-offset-2 hover:bg-blue-600`,

    variant === "secondary" &&
      `border text-gray-700` +
        (!active
          ? ` border-gray-200 bg-gray-100 hover:border-gray-300 hover:bg-gray-200`
          : ` border-gray-300 bg-gray-200`),

    variant === "negative" && `bg-red-500 text-white hover:bg-red-600`,

    disabled && `cursor-not-allowed opacity-50`,
  ]);
});

useKeybinds(() => {
  const hotkey = props.hotkey;
  if (!hotkey || props.disabled) {
    return;
  }

  return {
    [hotkey](ev) {
      if (ev.defaultPrevented) {
        return;
      }

      if (props.route) {
        router.push(props.route);
      } else {
        emit("click");
      }

      return true;
    },
  };
});
</script>
