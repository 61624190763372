<template>
  <ToggleGroupRoot
    type="single"
    orientation="horizontal"
    :model-value="activePreset"
    :class="[
      `flex items-center gap-1`,
      !isOverflown && `rounded-md border border-gray-200 p-1`,
    ]"
    @update:model-value="setPreset"
  >
    <template v-if="!isOverflown">
      <PresetButton
        v-for="parameter in defaultParameters"
        :key="parameter.name"
        :parameter="parameter"
      />
    </template>

    <PopoverRoot>
      <PopoverTrigger as-child>
        <ToggleGroupItem
          :value="customId"
          :class="[
            `group flex items-center gap-2 rounded-md px-2 py-1 text-sm font-medium transition-colors`,
            !isOverflown
              ? activePreset !== customId
                ? `text-gray-600 hover:bg-gray-200 hover:text-gray-700`
                : `bg-gray-600 text-white`
              : `border border-gray-200 bg-gray-100 text-gray-700 hover:bg-gray-200`,
          ]"
        >
          <span>Filter</span>
          <span
            v-if="activePreset === customId && count !== undefined"
            class="hidden font-mono text-xs font-bold text-gray-300 lg:inline"
          >
            {{ count }}
          </span>
        </ToggleGroupItem>
      </PopoverTrigger>

      <PopoverPortal>
        <PopoverContent
          align="end"
          :side-offset="8"
          :collision-padding="10"
          class="z-20 w-64 overflow-y-auto rounded border border-gray-300 bg-white shadow-lg"
          style="max-height: var(--radix-popper-available-height)"
        >
          <ToggleGroupRoot
            v-if="isOverflown"
            type="single"
            orientation="vertical"
            class="flex flex-col p-2 border-b border-gray-200"
            :model-value="activePreset"
            @update:model-value="setPreset"
          >
            <PresetButton
              v-for="parameter in defaultParameters"
              :key="parameter.name"
              :parameter="parameter"
            />
          </ToggleGroupRoot>

          <AdvancedFilterMenu
            v-model="state"
            v-model:expanded="open"
            :aggregates="aggregates"
            :parameter-set="parameterSet"
          />
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  </ToggleGroupRoot>
</template>

<script setup lang="ts">
/**
 * If type = checkboxes, state[id] = [premium, churning]
 */

import isEqual from "fast-deep-equal";
import {
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
  ToggleGroupItem,
  ToggleGroupRoot,
} from "radix-vue";
import { computed, ref, useId } from "vue";

import { useMediaQuery } from "@/lib/media_query";
import { Aggregate } from "@/types/aggregate";

import AdvancedFilterMenu from "./advanced/AdvancedFilterMenu.vue";
import { DefaultParameter, Parameter, ParameterSet } from "./lib";
import PresetButton from "./PresetButton.vue";

const state = defineModel<Parameter<string>>({ required: true });

const customId = `filter-${useId()}`;

const props = defineProps<{
  parameterSet: ParameterSet<string>[];
  defaultParameters: DefaultParameter<string>[];
  aggregates: Record<string, Aggregate<string>[] | undefined>;
  count: number;
  forceOverflow?: boolean;
}>();

const isDesktop = useMediaQuery("(min-width: 1024px)"); // lg
const isOverflown = computed(() => props.forceOverflow || !isDesktop.value);

const open = ref<string[]>([]);

const activePreset = computed(() => {
  const currentState = state.value;
  const found = props.defaultParameters.find((parameter) => {
    return isEqual(parameter.parameters, currentState);
  });

  return found?.name ?? customId;
});

const setPreset = (name: string | string[]) => {
  if (typeof name !== "string" || !name) {
    return;
  }

  if (name === customId) {
    return;
  }

  const found = props.defaultParameters.find((param) => param.name === name);

  if (found) {
    state.value = found.parameters;
  }
};
</script>
