import { defineStore } from "pinia";
import { ref, watch } from "vue";

import internalApi from "@/lib/api/internal-base";
import { useNewsletter } from "@/lib/newsletter";
import { ok } from "@/lib/openapi";
import offlineSearch from "@/search/executor";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";
import { SearchResult } from "@/types/search_result";

export const useStore = defineStore(
  "search",
  () => {
    const scaffoldingStore = useScaffoldingStore();
    const newsletter = useNewsletter();
    const pending = ref(false);
    const value = ref("");
    const results = ref<SearchResult[]>([]);

    watch(value, async (query, _prev, cleanup) => {
      if (query === "") {
        pending.value = false;
        results.value = [];
      } else {
        pending.value = true;

        const controller = new AbortController();
        cleanup(() => controller.abort());

        try {
          const offlineSearchResults = offlineSearch(
            query,
            scaffoldingStore.currentPermissions,
            newsletter.newsletter.value
          );
          results.value = offlineSearchResults;

          const onlineSearch = await ok(
            internalApi.post("/search", {
              signal: controller.signal,
              body: {
                text: value.value,
              },
            })
          );

          results.value = [
            ...offlineSearchResults,
            ...(onlineSearch.results as any as SearchResult[]),
          ];
        } finally {
          pending.value = false;
        }
      }
    });

    return {
      pending,
      value,
      results,
    };
  },
  {
    broadcast: {
      omit: ["value"],
    },
  }
);
