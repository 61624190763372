<template>
  <input
    :id="id"
    v-model="modelValue"
    type="checkbox"
    :indeterminate="indeterminate"
    :name="name"
    :aria-labelledby="labeledBy"
    class="rounded border-gray-300 text-blue-500 transition-colors focus:ring-offset-0"
    @click="emit('click', $event)"
    @input="emit('input', $event)"
  />
</template>

<script lang="ts" setup>
defineProps<{
  indeterminate?: boolean;
  id?: string;
  labeledBy?: string;
  name?: string;
}>();

const modelValue = defineModel<boolean>("modelValue");
const emit = defineEmits<{
  click: [event: MouseEvent];
  input: [event: Event];
}>();
</script>
