<template>
  <label :for="props.id" class="mb-0 text-gray-900 text-[14px] font-medium">
    <div v-if="props.beta" class="flex items-center space-x-1">
      <legend v-if="props.legend"><slot></slot></legend>
      <div v-else><slot></slot></div>

      <div
        class="inline-block rounded-full text-xs uppercase bg-green-500 text-white font-bold px-2 py-1"
      >
        Beta
      </div>
    </div>
    <legend v-else-if="props.legend">
      <slot></slot>
    </legend>
    <div v-else>
      <slot></slot>
    </div>
  </label>
</template>

<script setup lang="ts">
const props = defineProps<{ beta?: boolean; id?: string; legend?: boolean }>();
</script>
