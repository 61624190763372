<template>
  <tr
    :data-key="dataKey"
    :class="[
      `group transition-colors`,
      !selected && `hover:bg-gray-100`,
      selected && `bg-blue-100 hover:bg-blue-200`,
      active && `bg-gray-200`,
    ]"
  >
    <SelectionCell v-if="selection" />

    <slot></slot>
  </tr>
</template>

<script setup lang="ts" generic="Model extends { id: string | number }">
import { computed, useId } from "vue";

import { Selection } from "@/components/Layout/ListView/lib";

import { provide } from "./context";
import SelectionCell from "./SelectionCell.vue";
import { isRowSelected } from "./utils";

const props = defineProps<{
  item: Model;
  selection?: Selection<Model>;
  active?: boolean;
  dataKey?: string | number;
  onModelClicked?: (item: Model, event: Event) => void;
}>();

const selected = computed(() => {
  const selection = props.selection;
  if (selection) {
    return isRowSelected(selection, props.item);
  }

  return undefined;
});

provide({
  get item() {
    return props.item;
  },

  titleId: useId(),

  get selection() {
    return props.selection;
  },
  get selected() {
    return selected.value;
  },
  get active() {
    return props.active;
  },

  get onModelClicked() {
    const onModelClicked = props.onModelClicked;

    if (onModelClicked) {
      return (event: Event) => onModelClicked(props.item, event);
    }

    return undefined;
  },
});
</script>
