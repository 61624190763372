import { inject as vInject, InjectionKey, provide as vProvide } from "vue";

import { Selection } from "@/components/Layout/ListView/lib";

export type GenericModel = { id: string | number };

interface RowContext<Model extends GenericModel> {
  item: Model;

  titleId: string | undefined;

  selection?: Selection<Model>;
  selected?: boolean;
  active?: boolean;

  onModelClicked?: (event: Event) => void;
}

const injectionKey: InjectionKey<RowContext<any>> = Symbol();

export const provide = (row: RowContext<any>): void => {
  vProvide(injectionKey, row);
};

export const inject = <Model extends GenericModel>(): RowContext<Model> => {
  const row = vInject(injectionKey);
  if (row === undefined) {
    throw new Error("`inject` was not called under <Table.Row> component");
  }

  return row;
};
