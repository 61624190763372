import { Selection } from "@/components/Layout/ListView/lib";

export const isRowSelected = <Model extends { id: string | number }>(
  selection: Selection<Model>,
  item: Model
): boolean => {
  switch (selection.mode) {
    case "all": {
      return true;
    }
    case "all_except": {
      return selection.items.every((x) => x.id !== item.id);
    }
    case "some": {
      return selection.items.some((x) => x.id === item.id);
    }
  }
};
