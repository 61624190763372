import { ComputedRef, Ref, watch } from "vue";
import { useRouter } from "vue-router/auto";

import { components as OpenAPI } from "@/autogen/openapi";
import { useLocalStorageBackedRef } from "@/store/stores/base";
import { useStore } from "@/store/stores/bulk_actions";

export type Selection<E> =
  | {
      mode: "all";
    }
  | {
      mode: "all_except";
      items: E[];
    }
  | {
      mode: "some";
      items: E[];
    };

export const NO_IDS_SENTINEL = ",";

export const createIds = (selection: Selection<{ id: string }>): string => {
  if (selection.mode === "all") {
    return "all";
  }
  return selection.items.map((event) => event.id).join(",") || NO_IDS_SENTINEL;
};

export type ColumnSpec<Column> = {
  label: string;
  columns: {
    key: Column;
    always?: boolean;
    default?: boolean;
    label: string;
  }[];
}[];

export const useColumnSpec = (
  id: string,
  spec: ColumnSpec<string>
): Ref<string[]> => {
  const columns = useLocalStorageBackedRef<string[]>(
    `columns:${id}`,
    spec.flatMap((section) =>
      section.columns
        .filter((column) => column.default)
        .map((column) => column.key)
    ),
    (value) => JSON.stringify(value),
    (value) =>
      value.length
        ? value.includes("[")
          ? JSON.parse(value)
          : value.split(",")
        : null
  );
  return columns;
};

export const createDraftBulkAction = (
  type: OpenAPI["schemas"]["BulkActionType"],
  item: {
    id: string;
  }
) => {
  const bulkActionsStore = useStore();
  bulkActionsStore.setDraft({
    action_type: type,
    selection: {
      mode: "some",
      items: [item],
    },
  });
};

// Given a compatible store, keep URL up to date with current parameters.
export const useQueryParameters = <ParameterType extends string>(
  parameters: ComputedRef<{ [key in ParameterType]?: string[] | undefined }>
) => {
  const router = useRouter();
  const updateQuery = () => {
    const parameterQuery = (
      Object.keys(parameters.value) as ParameterType[]
    ).reduce((acc, parameter) => {
      const parameterValue = parameters.value[parameter];
      if (parameterValue) {
        acc[parameter + "[]"] = parameterValue;
      }
      return acc;
    }, {} as Record<string, string[]>);

    router.replace({
      query: {
        ...parameterQuery,
      },
    });
  };
  updateQuery();
  watch(() => JSON.stringify(parameters.value), updateQuery);
};
