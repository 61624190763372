import Container from "./Container.vue";
import Divider from "./Divider.vue";
import Dropdown from "./Dropdown.vue";
import Item from "./Item.vue";
import LinkItem from "./LinkItem.vue";
import Responsive from "./Responsive.vue";
import Root from "./Root.vue";
import RouteItem from "./RouteItem.vue";
import SectionHeader from "./SectionHeader.vue";
import Submenu from "./Submenu.vue";
import ToggleItem from "./ToggleItem.vue";
import Trigger from "./Trigger.vue";
import TriggerButton from "./TriggerButton.vue";

export default {
  Container,
  Divider,
  Dropdown,
  Item,
  LinkItem,
  Responsive,
  Root,
  RouteItem,
  SectionHeader,
  Submenu,
  ToggleItem,
  Trigger,
  TriggerButton,
};
