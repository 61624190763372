const isoWeekStartsOn = 1; // Monday

const coerceDate = (date: Date | string | number): Date => {
  return date instanceof Date ? date : new Date(date);
};

export const formatISODate = (date: Date | string | number): string => {
  // https://stackoverflow.com/a/58633686
  return coerceDate(date).toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatISOTime = (date: Date | string | number): string => {
  return coerceDate(date).toLocaleString("sv-SE", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

export const formatTime = (date: Date | string | number): string => {
  return coerceDate(date).toLocaleString(undefined, {
    timeStyle: "short",
  });
};

export const formatDate = (date: Date | string | number): string => {
  return coerceDate(date).toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatDateTime = (date: Date | string | number): string => {
  return coerceDate(date).toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

export const formatRelativeTime = (
  a: Date | string | number,
  b: Date | string | number = Date.now()
): string => {
  const SECOND = 1e3;
  const MINUTE = SECOND * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const MONTH = DAY * 30;
  const YEAR = DAY * 365.25;

  const delta = Math.abs(new Date(b).getTime() - new Date(a).getTime());

  let value: number;
  let unit: Intl.RelativeTimeFormatUnit;

  if (delta < MINUTE) {
    return `less than a minute`;
  } else if (delta < HOUR) {
    value = Math.floor(delta / MINUTE);
    unit = "minute";
  } else if (delta < DAY) {
    value = Math.round(delta / HOUR);
    unit = "hour";
  } else if (delta < MONTH) {
    // Use rounding, this handles the following scenario:
    // - 2024-02-13T09:00Z <- 2024-02-15T07:00Z = 2d
    value = Math.round(delta / DAY);
    unit = "day";
  } else if (delta < YEAR) {
    value = Math.floor(delta / MONTH);
    unit = "month";
  } else {
    value = Math.floor(delta / YEAR);
    unit = "year";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "unit",
    unit: unit,
    unitDisplay: "long",
  });

  return formatter.format(value);
};

// Day utilities
export const addDays = (date: Date | string | number, amount: number): Date => {
  const cloned = new Date(date);
  cloned.setDate(cloned.getDate() + amount);

  return cloned;
};

// Week utilities
export const startOfISOWeek = (date: Date | string | number): Date => {
  const cloned = new Date(date);

  const day = cloned.getDay();
  const diff = (day < isoWeekStartsOn ? 7 : 0) + day - isoWeekStartsOn;

  cloned.setDate(cloned.getDate() - diff);
  cloned.setHours(0, 0, 0, 0);

  return cloned;
};

export const endOfISOWeek = (date: Date | string | number): Date => {
  const cloned = new Date(date);

  const day = cloned.getDay();
  const diff = (day < isoWeekStartsOn ? -7 : 0) + 6 - (day - isoWeekStartsOn);

  cloned.setDate(cloned.getDate() + diff);
  cloned.setHours(23, 59, 59, 999);
  return cloned;
};

// Month utilities
export const addMonths = (
  date: Date | string | number,
  amount: number
): Date => {
  const cloned = new Date(date);
  cloned.setMonth(cloned.getMonth() + amount);

  return cloned;
};

export const startOfMonth = (date: Date | string | number): Date => {
  const cloned = new Date(date);
  cloned.setDate(1);
  cloned.setHours(0, 0, 0, 0);

  return cloned;
};

export const endOfMonth = (date: Date | string | number): Date => {
  const cloned = new Date(date);
  cloned.setFullYear(cloned.getFullYear(), cloned.getMonth() + 1, 0);
  cloned.setHours(23, 59, 59, 999);

  return cloned;
};

// Year utilities
export const addYears = (date: Date | string | number, amount: number) => {
  const cloned = new Date(date);
  cloned.setFullYear(cloned.getFullYear() + amount);

  return cloned;
};

export const startOfYear = (date: Date | string | number): Date => {
  const cloned = new Date(date);
  cloned.setFullYear(cloned.getFullYear(), 0, 1);
  cloned.setHours(0, 0, 0, 0);

  return cloned;
};

export const endOfYear = (date: Date | string | number): Date => {
  return new Date(new Date(date).getFullYear(), 11, 31);
};
