<template>
  <ToastProvider>
    <!--
      Annoyingly enough, ToastViewport doesn't teleport to body, so it gets
      rendered under the dialog rather than over it. Let's teleport it to the
      where it should've been.
    -->
    <Teleport to="body">
      <div class="scope">
        <!-- Where the toasts gets portalled to -->
        <ToastViewport class="viewport" />
      </div>
    </Teleport>

    <!-- Toasts from `showToast` are rendered here -->
    <Toast v-for="toast in toasts" :key="toast.id" :toast="toast" />

    <!-- Pending bulk actions toasts -->
    <BulkActionToast
      v-for="action in pendingBulkActions"
      :key="action.id"
      :bulk-action="action"
    />
  </ToastProvider>
</template>

<script setup lang="ts">
import { ToastProvider, ToastViewport } from "radix-vue";
import { computed } from "vue";

import Toast from "@/design_system/Toast.vue";
import { UNSTABLE_toasts as toasts } from "@/lib/toasts";
import { useStore as useBulkActionsStore } from "@/store/stores/bulk_actions";

import BulkActionToast from "./BulkActionToast.vue";

const bulkStore = useBulkActionsStore();

const pendingBulkActions = computed(() => {
  return bulkStore.bulkActions.filter((action) => {
    return action.status !== "processed";
  });
});
</script>

<style lang="scss" scoped>
// These styles don't seem convenient to write in Tailwind.

// We're wrapping it under .scope class because radix-vue doesn't accept
// scoped styling.
.scope {
  :deep(.viewport) {
    --viewport-padding: 24px;
    @apply fixed bottom-0 right-0;
    @apply flex flex-col gap-3;
    @apply m-0;
    padding: var(--viewport-padding);
    width: 400px;
    max-width: 100vw;
    list-style: none;
    z-index: 2147483647;
    outline: none;
  }

  :deep(.toast) {
    &[data-state="open"] {
      animation: slide-in 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state="closed"] {
      animation: hide 100ms ease-in;
    }
    &[data-swipe="move"] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }
    &[data-swipe="cancel"] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }
    &[data-swipe="end"] {
      animation: swipe-out 100ms ease-out;
    }
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipe-out {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}
</style>
