<template>
  <component :is="!parent.isMobile ? DropdownMenuSub : DrawerRootNested">
    <component
      :is="!parent.isMobile ? DropdownMenuSubTrigger : DrawerTrigger"
      as-child
    >
      <button
        type="button"
        :disabled="props.disabled"
        :class="[
          'group/item',
          'relative flex shrink-0 cursor-pointer select-none items-center gap-3 self-stretch rounded-md border-none px-3 transition-colors duration-75',
          'focus-visible:outline outline-2 outline-gray-100',
          'text-left text-sm',
          !parent.isMobile && 'h-7',
          parent.isMobile && 'h-9',
          props.disabled && 'opacity-50 cursor-not-allowed pointer-events-none',
          'hover:bg-gray-700 data-[state=open]:bg-gray-700',
        ]"
      >
        <slot v-if="slots.icon" name="icon" />
        <component
          :is="iconMappingMicro[props.icon]"
          v-else-if="props.icon"
          class="size-4 shrink-0 text-gray-400"
        />

        <span class="min-w-0 grow break-words">
          {{ props.label }}
        </span>

        <ChevronDownIcon
          class="size-4 shrink-0 -rotate-90 -mr-1 text-gray-400"
        />
      </button>
    </component>

    <Container>
      <slot></slot>
    </Container>
  </component>
</template>

<script setup lang="ts">
import { DropdownMenuSub, DropdownMenuSubTrigger } from "radix-vue";
import { DrawerRootNested, DrawerTrigger } from "vaul-vue";

import ChevronDownIcon from "@/icons/heroicons/chevron-down-micro.svg";
import { iconMappingMicro, MappedIconMicro } from "@/icons/icon-mapping-micro";

import Container from "./Container.vue";
import { inject, provide } from "./context";

const parent = inject();

const props = defineProps<{
  icon?: MappedIconMicro;
  label?: string;
  disabled?: boolean;
}>();

const slots = defineSlots<{
  icon?(): unknown;
  default?(): unknown;
}>();

provide({
  isNested: true,
  get isMobile() {
    return parent.isMobile;
  },
});
</script>
