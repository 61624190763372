import { computed, ComputedRef } from "vue";

import { useStore } from "@/store/stores/subscribers";
import { Newsletter } from "@/types/newsletter";

import { Parameter } from "./parameters";

const fetchCount = (params: any): ComputedRef<number | undefined> => {
  return computed(() =>
    useStore()
      .fieldToGlobalAggregateCount.type?.filter(
        (aggregate) =>
          params.type.includes(aggregate.parameter) || params.type.length === 0
      )
      .reduce((acc, aggregate) => acc + aggregate.count, 0)
  );
};

export const DEFAULT_PARAMETERS: {
  [key in Parameter]: any;
} = {
  type: [],
  utm_source: [],
  tag: [],
  date: [],
  price: [],
  coupon: [],
  last_open_date: [],
  last_click_date: [],
  subscriber_import: [],
  referral_code: [],
  ip_address: [],
  referrer_url: [],
  upgrade_date: [],
  upgrade_date__start: [],
  upgrade_date__end: [],

  // Unused.
  ids: [],
  email_address: undefined,
  expand: undefined,
  "-tag": undefined,
  ordering: undefined,
};

const ALL = {
  name: "All",
  parameters: DEFAULT_PARAMETERS,
  fetchCount,
};

export default (newsletter: Newsletter) => {
  if (newsletter.paid_subscriptions_status === "active") {
    return [
      ALL,
      {
        name: "Active",
        parameters: {
          ...DEFAULT_PARAMETERS,
          type: [
            "regular",
            "premium",
            "churning",
            "past_due",
            "gifted",
            "churned",
            "trialed",
            "paused",
          ],
        },
        fetchCount,
      },
      {
        name: "Premium",
        parameters: {
          ...DEFAULT_PARAMETERS,
          // 2024-01-12: I used to have this _just_ be premium folks, but a user made the case that it
          // makes more sense to have this be the list of all types which would _receive_ a premium email.
          type: ["premium", "churning", "gifted", "trialed"],
        },
        fetchCount,
      },
    ];
  } else {
    return [
      ALL,
      {
        name: "Active",
        parameters: {
          ...DEFAULT_PARAMETERS,
          type: ["regular"],
        },
        fetchCount,
      },
    ];
  }
};
