import { paths } from "@/autogen/openapi";
import { useStore } from "@/store/stores/scaffolding";

import { createClient } from "../openapi";

const api = createClient<paths>({
  base: "/v1",
  middlewares: [
    async (request, next) => {
      const { newsletter } = useStore();
      if (newsletter == null) {
        return next(request);
      }

      const url = request.url;

      if (newsletter.stripe_account === "") {
        // This is janky, but the right way to do it for now.
        if (url.includes("/prices") || url.includes("/coupons")) {
          return Response.json({ results: [] }, { status: 209 });
        }
      }

      request.headers.set("authorization", `Token ${newsletter.api_key}`);
      request.headers.set("x-api-version", `2025-01-02`);
      return next(request);
    },
  ],
});

export default api;
