<template>
  <component
    :is="!context.isMobile ? DropdownMenuItem : DrawerClose"
    as-child
    @click="updateSelection"
  >
    <button
      type="button"
      :disabled="props.disabled"
      :class="[
        'group/item',
        'relative flex shrink-0 cursor-pointer select-none items-center gap-3 self-stretch rounded-md border-none px-3 transition-colors duration-75',
        'focus-visible:outline outline-2 outline-gray-100',
        'text-left text-xs',
        !context.isMobile ? `h-6` : 'h-9',
        props.disabled && 'opacity-50 cursor-not-allowed pointer-events-none',
        !isChecked ? 'hover:bg-gray-800' : 'item--selected bg-gray-700',
      ]"
    >
      <span class="min-w-0 grow break-words">
        {{ props.label }}
      </span>

      <template v-if="isChecked">
        <div v-if="!multiple" class="grid h-4 w-4 shrink-0 place-items-center">
          <div class="h-2 w-2 rounded-full bg-blue-400"></div>
        </div>
        <CheckIcon
          v-else
          class="h-4 w-4 shrink-0 transition-colors text-blue-400"
        />
      </template>
      <div v-else class="h-4 w-4 shrink-0"></div>
    </button>
  </component>
</template>

<script setup lang="ts" generic="T extends Value">
import { DropdownMenuItem } from "radix-vue";
import { DrawerClose } from "vaul-vue";
import { computed } from "vue";

import CheckIcon from "@/icons/heroicons/check-micro.svg";

import { inject } from "./context";

export type Value = string | boolean;

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;

const context = inject();

const select = defineModel<XOR<T[], T | undefined>>({ required: true });
const multiple = Array.isArray(select.value);

const props = defineProps<{
  value: T;
  label: string;
  disabled?: boolean;
}>();

const isChecked = computed(() => {
  const selection = select.value;
  const value = props.value;

  if (Array.isArray(selection)) {
    return selection.includes(value);
  } else {
    return selection === value;
  }
});

const updateSelection = () => {
  const value = props.value;
  const selection = select.value;

  const isArray = Array.isArray(selection);

  if (!isChecked.value) {
    // @ts-expect-error
    select.value = isArray ? [...selection, value] : value;
  } else {
    // @ts-expect-error
    select.value = isArray ? selection.filter((v) => v !== value) : value;
  }
};
</script>

<style scoped>
.item--selected + .item--selected {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.item--selected:has(+ .item--selected) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
