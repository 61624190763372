<template>
  <Cell>
    <div class="flex items-center">
      <Checkbox
        :model-value="rowProps.selected"
        @click="rowProps.onModelClicked"
      />
    </div>
  </Cell>
</template>

<script setup lang="ts">
import Checkbox from "@/components/Utilities/Forms/Checkbox.vue";

import Cell from "./Cell.vue";
import { inject } from "./context";

const rowProps = inject();
</script>
