<template>
  <DialogRoot :open="isOpen" @update:open="closeModal">
    <DialogPortal>
      <div class="dialog">
        <DialogOverlay
          class="dialog--overlay fixed inset-0 -z-10 bg-gray-500 bg-opacity-75"
        />

        <DialogContent
          :aria-describedby="undefined"
          class="dialog--content"
          :class="[`max-h-full overflow-hidden rounded-lg bg-white shadow-xl`]"
        >
          <VisuallyHidden as-child>
            <DialogTitle>Quick search</DialogTitle>
          </VisuallyHidden>

          <ComboboxRoot
            v-model:search-term="modelValue"
            class="contents"
            @update:model-value="act"
          >
            <div class="relative flex items-center">
              <div class="ml-3.5 w-6 grid place-items-center text-gray-600">
                <ArrowPathIcon v-if="pending" class="h-4 w-4 animate-spin" />
                <MagnifyingGlassIcon v-else class="h-4 w-4" />
              </div>

              <ComboboxInput
                class="h-12 grow border-0 bg-transparent px-3 text-sm placeholder:text-gray-400 focus:ring-0"
                placeholder="Search by email, name, content..."
              />
            </div>

            <ComboboxContent
              v-if="data.length > 0"
              class="border-t border-gray-200"
            >
              <div class="max-h-96 overflow-y-auto px-4 py-3">
                <SearchItem
                  v-for="item in data.slice(0, 10)"
                  :key="item.obj.id"
                  :item="item"
                />

                <ComboboxItem
                  :key="modelValue"
                  :value="createViewAllAction(modelValue)"
                  class="-mx-2 mt-2 flex select-none gap-3 rounded-md px-1.5 py-1 text-gray-700 data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-900"
                >
                  <div
                    class="flex shrink-0 items-center justify-center self-start rounded bg-gray-200 p-1"
                  >
                    <EllipsisIcon class="h-4 w-4" />
                  </div>
                  <span class="my-0.5 grow text-sm font-medium">
                    View all results &rarr;
                  </span>
                </ComboboxItem>
              </div>
            </ComboboxContent>
            <ComboboxContent
              v-else-if="!pending && value !== '' && data.length === 0"
              class="py-14 px-6 text-center text-sm"
            >
              <ExclamationTriangleIcon
                icon="exclamation-triangle"
                class="mx-auto size-6 text-gray-500"
              />
              <p class="mt-4 font-semibold text-gray-900">
                No results found for {{ modelValue }}
              </p>
              <p class="mt-2 text-gray-500">
                No components found for this search term. Please try again.
              </p>
            </ComboboxContent>
          </ComboboxRoot>
        </DialogContent>
      </div>
    </DialogPortal>
  </DialogRoot>
</template>

<script lang="ts" setup>
import {
  ComboboxContent,
  ComboboxInput,
  ComboboxItem,
  ComboboxRoot,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  VisuallyHidden,
} from "radix-vue";
import { ref } from "vue";
import { useRouter } from "vue-router/auto";

import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import EllipsisIcon from "@/icons/heroicons/ellipsis-horizontal-micro.svg";
import ExclamationTriangleIcon from "@/icons/heroicons/exclamation-triangle-solid.svg";
import MagnifyingGlassIcon from "@/icons/heroicons/magnifying-glass-micro.svg";
import { type Action, createViewAllAction } from "@/lib/search";
import { useStore } from "@/store/stores/scaffolding";
import { SearchResult } from "@/types/search_result";

import SearchItem from "./SearchItem.vue";

defineProps<{
  pending: boolean;
  data: SearchResult[];
  value: string;
}>();

const emit = defineEmits(["close", "update:modelValue"]);

const modelValue = defineModel<string>({
  required: true,
});

const router = useRouter();
const store = useStore();

const isOpen = ref(true);

const closeModal = () => {
  isOpen.value = false;
  setTimeout(() => emit("close"), 250);
};

const act = (_action: unknown) => {
  const action = _action as Action;

  if (action.type === "route") {
    router.push(action.route);
  } else if (action.type === "action") {
    if (action.action === "switch_newsletter") {
      const newsletter = store.account?.permissions.find(
        (n) => n.newsletter.id === action.payload.newsletter
      )?.newsletter;
      if (newsletter) {
        store.switchNewsletter(newsletter);
      }
    }
  }

  closeModal();
};
</script>

<style lang="scss" scoped>
.dialog {
  display: grid;
  place-items: start center;
  position: fixed;
  inset: 0;
  z-index: 30;

  & {
    padding: 16px;
  }
  @media (min-width: 640px) {
    padding: 24px;
  }
  @media (min-width: 768px) {
    padding: 80px;
  }

  :deep(.dialog--overlay) {
    &[data-state="open"] {
      animation: overlay-fade-in 250ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state="closed"] {
      animation: overlay-fade-out 250ms cubic-bezier(0.16, 1, 0.3, 1);
    }
  }

  :deep(.dialog--content) {
    width: 100%;
    max-width: 600px;

    &[data-state="open"] {
      animation: content-scale-in 250ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state="closed"] {
      animation: content-scale-out 250ms cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
}

@keyframes overlay-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes overlay-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes content-scale-in {
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes content-scale-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.96);
  }
}
</style>
