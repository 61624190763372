<!-- eslint-disable vue/no-useless-template-attributes -->

<template>
  <div
    ref="scrollerRef"
    :class="[
      `-my-4 grow overflow-y-auto`,
      group || isHeaderShown ? `pb-4` : `py-4`,
    ]"
    @scroll="updateScrollTop"
  >
    <table class="w-full">
      <thead v-if="isHeaderShown">
        <tr>
          <th v-for="(column, idx) in columns" :key="column" class="group p-0">
            <button
              type="button"
              :disabled="!orders?.includes(column)"
              :class="[
                `block h-full w-full whitespace-nowrap px-2 pb-2 pt-4 text-xs font-bold uppercase tracking-wider text-gray-500 transition-colors hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-100`,
                `group-first:pl-4 group-last:pr-4`,
                idx !== lastActualColumn ? `text-left` : `text-right`,
              ]"
              @click="adjustSort(column)"
            >
              {{ mungeColumn(column) }}

              <template
                v-if="
                  column !== 'LEFT_SPACER_SENTINEL' &&
                  column !== 'RIGHT_SPACER_SENTINEL'
                "
              >
                <span v-if="order === column">⬆</span>
                <span v-else-if="order === `-${column}`">⬇</span>
              </template>
            </button>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="p-0" :style="{ height: `${scrollOffset}px` }"></td>
        </tr>

        <template v-for="{ token, index } in visibleTokens" :key="token.id">
          <tr
            v-if="token.type === 'header'"
            :ref="measureElement"
            :data-key="token.id"
          >
            <th colspan="100%" :class="[`p-0 pb-2`, index !== 0 && `pt-6`]">
              <div
                class="bg-gray-100 px-4 py-2 text-left text-xs font-bold uppercase tracking-wider text-gray-600"
              >
                {{ token.name }}
              </div>
            </th>
          </tr>
          <Row
            v-else-if="token.type === 'item'"
            :ref="measureElement"
            :item="token.item"
            :selection="selection"
            :data-key="token.id"
            @model-clicked="onModelClicked"
          >
            <slot
              name="row"
              :item="token.item"
              :index="index"
              :selection="selection"
            ></slot>
          </Row>
        </template>

        <tr>
          <td
            class="p-0"
            :style="{
              height: `${totalHeight - scrollOffset - visibleHeight}px`,
            }"
          ></td>
        </tr>
      </tbody>
    </table>

    <slot name="footer"></slot>
  </div>
</template>

<script setup lang="ts" generic="Model extends { id: string | number }">
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  shallowRef,
  toRef,
  watch,
} from "vue";

import { Selection } from "@/components/Layout/ListView/lib";
import { useMediaQuery } from "@/lib/media_query";

import Row from "../Table/Row.vue";

type RowProps<T> = {
  item: T;
  index: number;
  selection?: Selection<Model>;
};

type HeightCacheMap = { [key: string | number]: number | undefined };

defineSlots<{
  row(props: RowProps<Model>): void;
  footer(props: {}): void;
}>();

const props = defineProps<{
  items?: Model[];
  group?: string;

  columns?: string[];
  hideHeader?: boolean;

  orders?: readonly string[];

  selection?: Selection<Model>;
  onModelClicked?: (model: Model, event: Event) => void;
}>();

const isMobile = useMediaQuery(`(max-width: 1023px)`);
const isHeaderShown = computed(() => {
  return !props.hideHeader && props.columns !== undefined && !isMobile.value;
});

type Token =
  | { type: "header"; id: string; name: string }
  | { type: "item"; id: string; item: Model };

const tokens = computed((): Token[] => {
  const items = props.items;
  const group = props.group;

  if (items === undefined) {
    return [];
  }

  if (group === undefined) {
    return items.map((item): Token => {
      return { type: "item", id: `item-${item.id}`, item };
    });
  }

  const map = new Map<string, Model[]>();

  for (const item of items) {
    // @ts-expect-error
    const key = item[group];
    const list = map.get(key);

    if (list) {
      list.push(item);
    } else {
      map.set(key, [item]);
    }
  }

  return Array.from(map).flatMap(([name, child]): Token[] => {
    return [
      { type: "header", id: `header-${name}`, name },
      ...child.map((item): Token => {
        return { type: "item", id: `item-${item.id}`, item };
      }),
    ];
  });
});

const paddingStart = computed(() => {
  return isHeaderShown.value ? 40 : !props.group ? 16 : 0;
});

// #region Virtual table
const BUFFER = 5;
const ESTIMATE_HEIGHT = 26;

const scrollerRef = shallowRef<HTMLDivElement>();

const scrollTop = shallowRef(0);
const scrollWindowHeight = shallowRef<number>();
const adjustedScrollTop = toRef(() => scrollTop.value + paddingStart.value);

let elementCache: HTMLElement[] = [];
const heightCache = reactive<HeightCacheMap>({});

// If the items array changes, go through the height cache and see if there's
// anything we can remove
watch(tokens, (items) => {
  const ids = items.map((item) => "" + item.id);

  for (const cacheId in heightCache) {
    if (!ids.includes(cacheId)) {
      delete heightCache[cacheId];
    }
  }
});

const visibleRange = computed(() => {
  const $scrollWindowHeight = scrollWindowHeight.value;

  if ($scrollWindowHeight === undefined) {
    return { start: 0, end: BUFFER * 2 };
  }

  const $scrollTop = adjustedScrollTop.value;
  const $tokens = tokens.value;

  const len = $tokens.length;

  let currentHeight = 0;

  // Find start index
  let start = 0;
  for (; start < len; ) {
    const token = $tokens[start];
    const height = heightCache[token.id] ?? ESTIMATE_HEIGHT;

    if (currentHeight + height >= $scrollTop) {
      break;
    }

    currentHeight += height;
    start++;
  }

  // Find end index
  let end = start;
  currentHeight = 0;

  for (; end < len; end++) {
    const token = $tokens[end];
    const height = heightCache[token.id] ?? ESTIMATE_HEIGHT;

    currentHeight += height;
    if (currentHeight >= $scrollWindowHeight) {
      break;
    }
  }

  return {
    start: Math.max(0, start - BUFFER),
    end: Math.min(len, end + 1 + BUFFER),
  };
});

const visibleTokens = computed(() => {
  const { start, end } = visibleRange.value;

  return tokens.value.slice(start, end).map((token, idx) => {
    return { token: token, index: start + idx };
  });
});

const totalHeight = computed(() => {
  const $tokens = tokens.value;
  let height = 0;

  for (let idx = 0, len = $tokens.length; idx < len; idx++) {
    const token = $tokens[idx];
    height += heightCache[token.id] ?? ESTIMATE_HEIGHT;
  }

  return height;
});

const scrollOffset = computed(() => {
  const $tokens = tokens.value;
  const $start = visibleRange.value.start;
  let totalOffset = 0;

  for (let idx = 0; idx < $start; idx++) {
    const token = $tokens[idx];
    const height = heightCache[token.id] ?? ESTIMATE_HEIGHT;

    totalOffset += height;
  }

  return totalOffset;
});

const visibleHeight = computed(() => {
  const $visibleTokens = visibleTokens.value;
  let totalHeight = 0;

  for (let idx = 0, len = $visibleTokens.length; idx < len; idx++) {
    const item = $visibleTokens[idx];
    const height = heightCache[item.token.id] ?? ESTIMATE_HEIGHT;

    totalHeight += height;
  }

  return totalHeight;
});

const rowRO = new ResizeObserver((entries) => {
  for (let idx = 0, len = entries.length; idx < len; idx++) {
    const entry = entries[idx];
    const target = entry.target as HTMLElement;

    // Stale observer entries, these will report as 0px rect height.
    if (!target.isConnected) {
      rowRO.unobserve(target);
      continue;
    }

    const key = target.dataset.key as string;
    const height = entry.contentRect.height;

    if (key !== undefined) {
      heightCache[key] = height;
    }
  }
});

const updateScrollTop = () => {
  scrollTop.value = scrollerRef.value!.scrollTop;
};

const updateContainerHeight = () => {
  scrollWindowHeight.value = scrollerRef.value!.clientHeight;
};

const measureElement = (node: any) => {
  const el = node?.$el as HTMLElement | undefined;

  if (el) {
    rowRO.observe(el);
    elementCache.push(el);
    return;
  }

  elementCache = elementCache.filter((cachedEl) => {
    if (!cachedEl.isConnected) {
      rowRO.unobserve(cachedEl);
      return false;
    }

    return true;
  });
};

onMounted(() => {
  const scrollerRO = new ResizeObserver(updateContainerHeight);
  scrollerRO.observe(scrollerRef.value!);

  onUnmounted(() => {
    scrollerRO.disconnect();
    rowRO.disconnect();
  });
});

// #endregion

// #region Actual table stuff

const order = defineModel<string>("order");

const lastActualColumn = computed(() => {
  // @ts-expect-error: new API, not typed
  return props.columns?.findLastIndex((column) => {
    return (
      column !== "LEFT_SPACER_SENTINEL" && column !== "RIGHT_SPACER_SENTINEL"
    );
  });
});

const mungeColumn = (column: string) => {
  if (column === "page_views__lifetime") {
    return "Views (all)";
  }
  if (column === "page_views__30") {
    return "Views (past 30)";
  }
  if (column === "page_views__7") {
    return "Views (past 7)";
  }

  if (column === "LEFT_SPACER_SENTINEL") {
    return "";
  }
  if (column === "RIGHT_SPACER_SENTINEL") {
    return "";
  }

  return column.replaceAll("_", " ");
};

const adjustSort = (column: string) => {
  order.value = order.value === column ? `-${column}` : column;
};

// #endregion
</script>
