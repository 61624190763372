import { paths } from "@/autogen/internal-openapi";
import { useStore } from "@/store/stores/scaffolding";

import { createClient } from "../openapi";

const internalApi = createClient<paths>({
  base: "/api/emails/api",
  middlewares: [
    async (request, next) => {
      const { newsletter } = useStore();
      if (newsletter == null) {
        return next(request);
      }

      request.headers.set("authorization", `Token ${newsletter.api_key}`);
      return next(request);
    },
  ],
});

export default internalApi;
