import type { components as OpenAPI } from "@/autogen/openapi";
import { list as listEmails } from "@/components/Screens/Emails/BulkActions/lib";
import { type MappedIconMicro } from "@/icons/icon-mapping-micro";
import api from "@/lib/api/base";
import { listSubscribers } from "@/lib/api/subscribers";
import { ok } from "@/lib/openapi";
import { CreateParams } from "@/store/stores/bulk_actions";
import { useStore as useEmailsStore } from "@/store/stores/emails";
import { useStore as useOpenAPIMirrorStore } from "@/store/stores/openapi-mirror";

export type BulkActionConfiguration = {
  noun: string;
  icon: MappedIconMicro;
  verb: string;
  label: string;
  payload?: object;
  parameters?: any;
  count?: number;
  disabled?: boolean;
  handleSuccess?: () => void;
  header?: (o: any) => string;
  list?: (ids: string[]) => Promise<any>;
  gerund?: string;
};
export function generateBulkActionConfiguration({
  tag_ids,
  bulkAction,
  email,
  store,
  emailsStore,
  date,
  message,
}: {
  tag_ids?: string[];
  bulkAction?: CreateParams;
  email?: OpenAPI["schemas"]["Email"] | null;
  store?: ReturnType<typeof useOpenAPIMirrorStore>;
  emailsStore?: ReturnType<typeof useEmailsStore>;
  date?: string | null;
  message?: string;
}): {
  [key in OpenAPI["schemas"]["BulkActionType"]]: BulkActionConfiguration;
} {
  return {
    apply_metadata: {
      noun: "subscriber",
      icon: "code-bracket",
      verb: "Apply",
      label: "Applying metadata",
      gerund: "applying",
    },
    mark_subscribers_as_not_spammy: {
      noun: "subscriber",
      verb: "Vouch",
      icon: "arrow-uturn-right",
      label: "Restoring subscriber",
      gerund: "restoring",
    },
    update_email_types: {
      noun: "email",
      verb: "Update",
      icon: "eye",
      label: "Updating email type",
      gerund: "updating",
    },
    replay_events: {
      noun: "event",
      verb: "Replay",
      icon: "arrow-path-rounded-square",
      label: "Replaying event",
      gerund: "replaying",
    },
    update_survey_statuses: {
      noun: "survey",
      verb: "Update",
      icon: "eye",
      label: "Updating survey status",
      gerund: "updating",
    },
    send_emails: {
      noun: "subscriber",
      icon: "envelope",
      disabled: email === null,
      payload: { email_id: email?.id },
      verb: "Email",
      label: "Sending email",
      gerund: "sending",
    },
    apply_tags: {
      noun: "subscriber",
      icon: "tag",
      disabled: tag_ids?.length === 0,
      payload: { tag_ids: tag_ids, action: bulkAction?.action },
      verb:
        bulkAction?.action === "remove" ? "Remove tags from" : "Apply tags to",
      label: "Applying tag",
      gerund: "applying",
    },
    delete_tags: {
      noun: "tag",
      header: (tag: OpenAPI["schemas"]["Tag"]) => tag.name,
      count: store?.["/tags"].value.count,
      icon: "trash",
      handleSuccess: () => {
        store?.["/tags"].value.list();
      },
      verb: "Delete",
      list: async () => {
        const data = await ok(api.get("/tags"));

        return data.results.filter((tag) => bulkAction?.ids.includes(tag.id));
      },
      label: "Deleting tag",
      gerund: "deleting",
    },
    delete_comments: {
      noun: "comment",
      icon: "trash",
      header: (comment: OpenAPI["schemas"]["Comment"]) => comment.id,
      handleSuccess: () => {
        store?.["/comments"].value.list();
      },
      verb: "Delete",
      label: "Deleting comment",
      gerund: "deleting",
    },
    delete_emails: {
      noun: "email",
      icon: "trash",
      header: (email: OpenAPI["schemas"]["Email"]) =>
        email.subject || "(no subject)",
      parameters: emailsStore?.parameters,
      count: emailsStore?.count,
      handleSuccess: () => {
        emailsStore?.list();
        emailsStore?.aggregateEmails(emailsStore?.parameters);
        emailsStore?.aggregateGlobal();
      },
      verb: "Delete",
      list: listEmails,
      label: "Deleting email",
      gerund: "deleting",
    },
    delete_surveys: {
      noun: "survey",
      icon: "trash",
      handleSuccess: () => {
        store?.["/surveys"].value.list();
      },
      verb: "Delete",
      label: "Deleting survey",
      gerund: "deleting",
    },
    ban_subscribers: {
      noun: "subscriber",
      icon: "trash",
      header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
        subscriber.email_address,
      verb: "Ban",
      list: (ids: string[]) =>
        listSubscribers({ ids }).then((response) => response.results),
      label: "Banning subscriber",
      gerund: "banning",
    },
    delete_subscribers: {
      noun: "subscriber",
      icon: "trash",
      header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
        subscriber.email_address,
      verb: "Delete",
      list: (ids: string[]) =>
        listSubscribers({ ids }).then((response) => response.results),
      label: "Deleting subscriber",
      gerund: "deleting",
    },
    send_reminders: {
      noun: "subscriber",
      icon: "bell",
      header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
        subscriber.email_address,
      verb: "Remind",
      label: "Sending reminder",
      gerund: "sending",
    },
    unsubscribe_subscribers: {
      noun: "subscriber",
      icon: "user-minus",
      header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
        subscriber.email_address,
      verb: "Unsubscribe",
      list: (ids: string[]) =>
        listSubscribers({ ids }).then((response) => response.results),
      label: "Unsubscribing subscriber",
      gerund: "unsubscribing",
    },
    reactivate_subscribers: {
      noun: "subscriber",
      icon: "arrow-uturn-right",
      header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
        subscriber.email_address,
      verb: "Reactivate",
      list: (ids: string[]) =>
        listSubscribers({ ids }).then((response) => response.results),
      label: "Reactivating subscriber",
      gerund: "reactivating",
    },
    resubscribe_subscribers: {
      noun: "subscriber",
      icon: "arrow-uturn-left",
      header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
        subscriber.email_address,
      verb: "Resubscribe",
      list: (ids: string[]) =>
        listSubscribers({ ids }).then((response) => response.results),
      label: "Resubscribing subscriber",
      gerund: "resubscribing",
    },
    gift_subscribers: {
      noun: "subscriber",
      icon: "gift",
      verb: "Gift",
      label: "Granting gift subscription",
      header: (subscriber: OpenAPI["schemas"]["Subscriber"]) =>
        subscriber.email_address,
      payload: date === null ? { message } : { message, date },
      list: (ids: string[]) =>
        listSubscribers({ ids }).then((response) => response.results),
      gerund: "gifting",
    },
  };
}
